@import "../../App.scss";


.onboard-main{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    padding-top: 3%;
    padding-bottom: 3%;

    .magnify-img{
        position: absolute;
        width: 40px;
        height: 40px;
        object-fit: contain;
        left: 0;
        margin-top: -20px;
        margin-left: 40px;
        top:0;
    }

    .cloud-img{
        position: absolute;
        width: 90px;
        height: 60px;
        object-fit: contain;
        right: 0;
        margin-top: -30px;
        margin-right: 40px;
        top:0;
    }

    .onboard-main-control{
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(lg){
            width: 80%;
            margin-top: 24px;
            margin-bottom: 60px;
        }
        @include media-breakpoint-down(md){
            width: 90%;
            margin-top: 24px;
            padding-bottom: 60px;
        }
    }

    .onboard-loading-progress{
        margin: auto;
        width: 50%;
        margin: auto;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md){
            width: 90%;
            margin-top: 24px;
        }

        .onboard-loading-item{
            display: flex;
            flex-direction: column;
            margin: auto;
        }

        .onboard-loading{
            width: 25px;
            height: 25px;
            margin-left: auto;
            margin-right: auto;
        }

        .onboard-loading-text{
            font-size: 16px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
            margin-top: 16px;
        }
    }

    .onboard-steps-control{
        display: flex;
        margin-bottom: 36px;
        margin-left: auto;
        margin-right: auto;

        .onboard-steps-number-control-active{
            cursor: pointer;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: var(--primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-family: $fontMedium;
            align-content: center;
        }

        .onboard-steps-divider-active{
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 4px;
            margin-right: 4px;
            width: 200px;
            height: 2px;
            background-color: var(--primary-color);
            border-radius: 8px;
            @include media-breakpoint-down(md){
                width: 50px;
            }
        }
        .onboard-steps-divider{
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 4px;
            margin-right: 4px;
            width: 200px;
            height: 2px;
            background-color: var(--divider-color);
            border-radius: 8px;

            @include media-breakpoint-down(md){
                width: 50px;
            }
        }
        
        .onboard-steps-number-control{
            cursor: pointer;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: transparent;
            border: 2px solid var(--primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary-color);
            font-family: $fontMedium;
            align-content: center;
        }
    }
}