@import "../../App.scss";

.profile{
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;
    position: relative;

    @include media-breakpoint-down(md){
        padding: 12px 12px;
    }

    
    


    .profile-left-control{
        background-color: var(--primary-color);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 36px 36px;
        position: relative;

        @include media-breakpoint-down(lg){
            min-height: 100px;
        }

        .profile-left-menu{
            display: none;
           
            @include media-breakpoint-down(lg){
                cursor: pointer;
                display: block;
                position: absolute;
                width: auto;
                height: 30px;
                object-fit: contain;
                right: 0;
                margin-right: 16px;
            }
        }
        

        .profile-logo-jobhack{
            width: auto;
            height: 30px;
            object-fit: contain;
            margin-right: auto;
        }

        .profile-collapse-control{
            margin-top: auto;
            display: flex;

            .profile-collapse-img{
                margin-left: auto;
                font-size: 24px;
                color: white;
                cursor: pointer;
            }
        }
        
        .profile-img-control{
            display: flex;

            .profile-img{
                width: 150px;
                height: 150px;
                object-fit: contain;
            }
        }
        .profile-menu-item{
            display: flex;
            margin-top: 16px;
            cursor: pointer;
            @include media-breakpoint-down(lg){
                margin-right: auto;
            }

            .profile-menu-img{
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 16px;

                @include media-breakpoint-down(lg){
                    margin-right: 4px;
                }

            }

            .profile-menu-text{
                font-size: 18px;
                color: white;
                font-family: $fontMedium;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(lg){
                    font-size: 13px;
                }

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .profile-menu-control-mobile{
            display: grid;
            grid-template-columns: 33% 33% 33%;
            margin-top: 36px;
        }
        .profile-menu-control{
            display: flex;
            flex-direction: column;
            margin-top: 48px;

            @include media-breakpoint-down(lg){
                display: none;
            }
        }
    }

    .profile-right-control{
        background-color: var(--bg-color);
        height: 100%;
        width: 100%;
        display: flex;
        z-index: 1;
        flex-direction: column;
    }
}