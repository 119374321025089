@import "../../App.scss";


.guest-main{
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;

    @include media-breakpoint-down(md){
        padding: 4px 4px;
    }
}