@import "../../../../App.scss";

.anyfile-control{
    display: flex;
    flex-direction: column;
    margin-top: 12px;

  

    .anyfile-title-control{
        display: flex;
    }

    .anyfile-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;
      
    }
    .anyfile-title-limit{
        font-size: 12px;
        font-family: $fontRegular;
        color: var(--text-gray-color);
      
    }

    .anyfile-form-control{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 36px;
        .anyfile-form-group-control{
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            border-bottom: 1px solid var(--border-input);

            .anyfile-form-group-title{
                font-size: 14px;
                color: var(--text-color);
                font-family: $fontMedium;
            }

            .anyfile-form-input{
                height: 41px;
                width: 100%;
                font-size: 14px;
                font-family: $fontRegular;
                margin-bottom: 14px;
                border-radius: 8px;
                border: 1px solid var(--border-input);
            }

            
            .anyfile-form-input-textarea{
                height: 41px;
                width: 100%;
                font-size: 14px;
                font-family: $fontRegular;
                margin-bottom: 14px;
                border-radius: 8px;
                border: 1px solid var(--border-input);
                padding: 12px 16px;
            }
        }

        .anyfile-form-item-control{
            display: flex;
            margin-bottom: 14px;
        }

        .anyfile-form-button-remove{
            border: none;
            height: 41px;
            width: auto;
            background-color: var(--red-color);
            font-size: 14px;
            color: var(--text-white-color);
            font-family: $fontMedium;
            border-radius: 8px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
        }
        
    }
    .anyfile-form-input-title-control{
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: auto;
    }
    .anyfile-form-input-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;
    }
    .anyfile-submit-button{
        border: none;
        height: 41px;
       
        background-color: var(--primary-color);
        font-size: 14px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;
    }
    .anyfile-upload-button{
        border: 1px solid var(--button-primary-color);
        width: 250px;
        height: 41px;
        background-color: white;
        font-size: 13px;
        color: var(--button-primary-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-top: 4px;
        margin-left: auto;

        .anyfile-upload-button-icon{
            margin-right: 12px;
        }
        .anyfile-upload-button-text{
           font-size: 14px;
           margin-left: 8px;
        }
        &:hover{
            background-color: var(--button-primary-color);
            color: var(--text-white-color);
        }
    }
   
    .anyfile-add-button{
        margin-left: auto;
        border: none;
        width: 30%;
        height: 41px;
        background-color: var(--primary-color);
        font-size: 13px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: auto;

    }
   
}