@import "../../../../App.scss";


.template-2-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    .template-2-cms-background {

        background-color: white;
    }

    .template-2-footer-control {
        height: 50px;
        width: 100%;
    }

    .template-2-form-control {
        display: flex;
        flex-direction: column;
        background-color: white;

        .template-2-desc-control {
            display: flex;
            flex-direction: column;
            padding-left: 24px;
            padding-right: 24px;

            .template-2-desc-about-title {
                font-size: 18px;
                color: var(--text-color);
                font-family: $fontMedium;
                word-wrap: break-word;
                word-break: break-word;
            }

            .template-2-desc-about-desc {
                font-size: 12px;
                color: var(--text-color);
                font-family: $fontRegular;
                word-wrap: break-word;
                word-break: break-word;
            }

            .template-2-desc-grid-control {
                display: grid;
                grid-template-columns: 30% 70%;
                background-color: white;
            }

            .template-2-desc-section-control {
                margin-bottom: 16px;
                display: flex;
                padding-right: 16px;
                flex-direction: column;

                .template-2-desc-title {
                    font-size: 20px;
                    color: var(--text-color);
                    font-family: $fontMedium;
                    word-wrap: break-word;
                }

                .template-2-desc-divider {
                    width: 100%;
                    height: 3px;
                    background-color: var(--border-input);
                    margin-bottom: 16px;
                }

                .template-2-desc-content {
                    margin-top: 16px;
                    font-size: 13px;
                    word-wrap: break-word;
                    color: var(--text-color);
                    font-family: $fontRegular;
                }
            }
        }

        .template-2-divider {
            width: 100%;
            height: 3px;
            background-color: var(--border-input);
            margin-top: 24px;
            margin-bottom: 24px;

        }

        .template-2-profile-control {
            min-height: 300px;
            width: 100%;
            display: grid;
            grid-template-columns: 30% 70%;
            padding: 24px;
            background-image: linear-gradient(to bottom, black 50%, white 50%);
            /* Create the gradient */

            .template-2-profile-left-control {
                display: flex;
              

                .template-2-profile-img {
                    width: 200px;
                    height: 200px;
                    margin: auto;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

            }

            .template-2-profile-right-control {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding-left: 24px;
                padding-right: 12px;

                .template-2-profile-right-top-control {
                    display: flex;
                    flex-direction: column;
                    height: 50%;

                    .template-2-profile-right-top-name {
                        font-size: 24px;
                        font-family: $fontMedium;
                        color: white;
                        margin-top: 12px;
                        word-wrap: break-word;
                    }

                    .template-2-profile-right-top-position {
                        font-size: 22px;
                        font-family: $fontRegular;
                        color: white;
                        margin-right: 36px;
                        word-wrap: break-word;
                    }
                }

                .template-2-profile-right-bottom-control {
                    display: grid;
                    width: 100%;
                    grid-template-columns: 50% 50%;
                    height: 50%;
                    margin-top: 16px;

                    .template-2-profile-right-bottom-contact-control {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .template-2-profile-right-bottom-contact-title {
                            font-size: 16px;
                            font-family: $fontMedium;
                            color: black;
                            word-wrap: break-word;
                        }

                        .template-2-profile-right-bottom-contact-text-control{
                            display: grid;
                            grid-template-columns: 50% 50%;
                        }

                        .template-2-profile-right-bottom-contact-text {
                            font-size: 15px;
                            font-family: $fontRegular;
                            color: black;
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }

    }
}