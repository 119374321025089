@import "../../../App.scss";


.my-resume {
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;



    .my-resume-add-img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 16px;
    }

    .my-resume-import-or {
        font-size: 13px;
        margin-top: 8px;
        text-align: center;
        color: var(--text-color);
        font-family: $fontRegular;
    }

    .my-resume-import-resume {
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 8px;
        color: var(--link-color);
        font-family: $fontMedium;
    }

    .my-resume-add-button-control {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .my-resume-add-button-text {
            font-size: 14px;
            font-family: $fontRegular;
            color: var(--text-color);
            text-align: right;
            z-index: 1;

        }

        .my-resume-add-button-text-bold {
            font-size: 26px;
            font-family: $fontMedium;
            color: var(--text-color);
            text-align: right;
            margin-bottom: 14px;
        }
    }

    .my-resume-add-button {
        width: auto;
        height: 46px;
        margin-left: auto;
        background-color: white;
        border: 2px solid var(--primary-color);
        border-radius: 8px;
        color: var(--primary-color);
        font-family: $fontMedium;
        font-size: 16px;

        &:hover {
            background-color: var(--primary-color);
            color: white;
        }
    }

    .my-resume-control {
        display: flex;
        flex-direction: column;
        z-index: 1;

        .my-resume-header-control {
            display: flex;
            z-index: 2;

            .my-resume-header {
                font-size: 25px;
                color: var(--text-color);
                font-family: $fontMedium;
                margin-bottom: auto;
            }
        }

        .my-resume-list-control-desktop {
            background-color: white;
            border-radius: 24px;
            box-shadow: var(--shadow-box);
            margin-top: 36px;
            width: 100%;
            z-index: 1;

            .my-resume-table {
                border-radius: 8px;
            }
        }



        .my-resume-list-control {
            background-color: white;
            border-radius: 8px;
            margin-top: 36px;
            width: 100%;
            min-height: 500px;
            display: grid;
            grid-template-columns: 50% 50%;
            z-index: 1;



            .my-resume-item-control {
                display: flex;
                flex-direction: column;
                width: 70%;
                margin-left: auto;
                margin-right: auto;
                padding-top: 36px;

                @include media-breakpoint-down(md) {
                    width: 80%;
                }

                .my-resume-item-border {
                    cursor: pointer;
                    border: 2px solid var(--primary-color);
                    height: 300px;
                    width: 100%;
                    display: flex;
                    border-radius: 8px;

                    .my-resume-item-template-img {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }

                .my-resume-item-border-add {
                    cursor: pointer;
                    border: 2px solid var(--primary-color);
                    height: 300px;
                    width: 100%;
                    border-radius: 8px;
                    display: flex;

                    .my-resume-item-border-add-img {
                        width: 100px;
                        height: 100px;
                        object-fit: contain;
                        margin: auto;
                    }
                }

                .my-resume-item-name {
                    font-family: $fontMedium;
                    font-size: 19px;
                    color: var(--text-color);
                    margin-top: 16px;
                    margin-left: auto;
                    margin-right: auto;
                }






            }
        }

    }

    .my-resume-item-cell-text {
        font-size: 13px;
        color: var(--text-color);
        font-family: $fontRegular;
        margin-top: auto;
        margin-bottom: auto;
    }

    .my-resume-table-size {
        width: 100%;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .my-resume-collapse-size {
            margin: auto;
            display: none;

            @include media-breakpoint-down(md) {
                display: table-cell;

            }
        }
    }

    .my-resume-item-status-control {
        display: flex;
        flex-direction: column;
    }

    .my-resume-item-default-tag {
        width: 100px;
        height: 32px;
        margin-right: auto;
        border-radius: 8px;
        border: 1px solid var(--button-share-color);
        color: var(--button-share-color);
        background-color: white;
        font-family: $fontMedium;
        font-size: 13px;
    }

    .my-resume-item-button-control {
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: 15px;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 100%;
        }

        .my-resume-item-share-view-button {
            min-width: 90%;
            height: 32px;
            margin-left: auto;
            margin-right: auto;
            border: none;
            border-radius: 8px;
            background-color: var(--link-color);
            color: white;
            font-family: $fontMedium;
            font-size: 13px;
        }


        .my-resume-item-share-button {
            width: 90%;
            height: 32px;
            margin-left: auto;
            margin-right: auto;
            border: none;
            border-radius: 8px;
            background-color: var(--button-share-color);
            color: white;
            font-family: $fontMedium;
            font-size: 13px;
            cursor: pointer;
        }

        .my-resume-item-delete-button {
            width: 90%;
            height: 32px;
            margin-left: auto;
            margin-right: auto;
            border: none;
            border-radius: 8px;
            background-color: var(--red-color);
            color: white;
            font-family: $fontMedium;
            font-size: 13px;
        }

        .my-resume-item-button {
            width: 90%;
            height: 32px;
            margin-left: auto;
            border: none;
            margin-right: auto;
            border-radius: 8px;
            background-color: var(--primary-color);
            color: white;
            font-family: $fontMedium;
            font-size: 13px;
        }
    }

    .no-resume-control {
        background-color: white;
        border-radius: 24px;
        box-shadow: var(--shadow-box);
        margin-top: 36px;
        width: 100%;
        display: flex;
        min-height: 500px;
        z-index: 1;

        .no-resume-text-control {
            display: flex;
            flex-direction: column;
            margin: auto;
            justify-content: center;
            width: 100%;
            height: 100%;

            .no-resume-text {
                font-size: 16px;
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
                font-family: $fontRegular;
            }

            .no-resume-add-button {
                width: auto;
                height: 46px;
                margin-left: auto;
                margin-right: auto;
                background-color: white;
                border: 2px solid var(--primary-color);
                border-radius: 8px;
                margin-top: 16px;
                color: var(--primary-color);
                font-family: $fontMedium;
                font-size: 16px;

                &:hover {
                    background-color: var(--primary-color);
                    color: white;
                }
            }
        }


    }

    .my-resume-share-text {
        font-size: 13px;
        margin-top: 12px;
        margin-left: auto;
        text-decoration: underline;
        margin-right: auto;
        color: var(--button-primary-color);
        font-family: $fontRegular;
    }

    .my-resume-loading-control {
        height: 60vh;
        width: 100%;
        display: flex;

        .my-resume-loading-flex {
            margin: auto;
            display: flex;
            flex-direction: column;

            .my-resume-loading {
                width: 10px;
                height: 10px;
                margin-left: auto;
                margin-right: auto;
            }

            .my-resume-loading-text {
                font-size: 14px;
                font-family: $fontRegular;
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
            }
        }
    }

}