@import "../../../App.scss";

.job-apply-main-control {
    width: 100vw;
    height: 100%;
    background-color:white;
    padding: 36px 36px;
    overflow-y: auto;

    @include media-breakpoint-down(md){
        padding: 16px 16px;
    }

    .job-apply-header {
        font-size: 25px;
        color: var(--text-color);
        font-family: $fontMedium;
        margin-bottom: auto;
    }

    .job-apply-send-control{
        z-index: 1;
        border-radius: 24px 24px 0px 0px;
        background: var(--soft-primary-color);
        padding: 26px 24px 46px 24px;
        display: flex;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -20px;
        @include media-breakpoint-down(lg){
            width: 100%;
            flex-direction: column;
        }
        @include media-breakpoint-down(md){
            width: 100%;
            flex-direction: column;
        }
       
    }
    .job-apply-send-control-reverse{
        z-index: 1;
        border-radius: 0px 0px 24px 24px;
        background: var(--soft-primary-color);
        padding: 46px 24px 26px 24px;
        display: flex;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -40px;
        @include media-breakpoint-down(lg){
            width: 100%;
            flex-direction: column;
        }
        @include media-breakpoint-down(md){
            width: 100%;
            flex-direction: column;
        }
       
    }
    .job-apply-send-text-header{
        font-size: 20px;
        font-family: $fontMedium;
        margin-top: auto;
        margin-bottom: auto;
        color: var(--text-color);
        word-break: break-word;

        @include media-breakpoint-down(md){
            font-size: 14px;
        }
       
    }

    .job-apply-send-button{
        padding: 10px 24px;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: auto;
        height: 41px;
        background-color: var(--button-primary-color);
        color: var(--text-white-color);
        font-family: $fontMedium;
        font-size: 14px;
        margin-left: auto;
        border: none;
    }
    .job-apply-send-button-disabled{
        padding: 10px 24px;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: auto;
        height: 41px;
        background-color: var(--border-input);
        color: var(--text-white-color);
        font-family: $fontMedium;
        font-size: 14px;
        margin-left: auto;
        border: none;
    }
    .jobs-apply-main-item-expand-error {
        font-size: 13px;
        font-family: $fontMedium;
        color: var(--red-color);
        margin-bottom: 8px;
    }

    .jobs-apply-questions-true-false-control {
        display: grid;
        grid-template-columns: 70% 30%;

        @include media-breakpoint-down(sm){
            grid-template-columns: 100%;
        }
    }

    .jobs-apply-questions-answer-control {
        display: flex;
        flex-direction: column;
    }

    .jobs-apply-questions-answer-textarea {
        width: 100%;
        font-size: 16px;
        border-radius: 8px;
        border: 1px solid var(--border-input);
        padding: 6px 16px;
    }

    .jobs-apply-submitting-control {

        min-height: 300px;
        display: flex;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;


        .jobs-apply-submitting-main {
            margin: auto;
            display: flex;
            flex-direction: column;

            .jobs-apply-submitting-loading-icon {
                margin-left: auto;
                margin-right: auto;
                font-size: 48px;
                color: var(--primary-color);
                animation: bounce 2s infinite;
            }

            .jobs-apply-submitting-loading-text {
                font-size: 16px;
                color: var(--text-color);
                font-family: $fontMedium;
                margin-top: 18px;
                text-align: center;
                word-break: break-word;
            }
        }

        @keyframes bounce {

            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }

            40% {
                transform: translateY(-30px);
            }

            60% {
                transform: translateY(-15px);
            }
        }
    }

    .jobs-apply-questions-true-false-icon-control {
        margin-left: auto;
        display: flex;

        @include media-breakpoint-down(sm){
            margin-right: auto;
            margin-left: 0;
        }

        .jobs-apply-questions-true-false-icon-false {
            cursor: pointer;
            font-size: 30px;
            margin-left: 4px;
            margin-right: 4px;
            color: var(--red-color);
        }

        .jobs-apply-questions-true-false-icon-true {
            cursor: pointer;
            font-size: 30px;
            margin-left: 4px;
            margin-right: 4px;
            color: var(--primary-color);

        }
    }

    .jobs-apply-questions-text-question-control{
        display: flex;
    }

    .jobs-apply-questions-text-question {
        display: flex;
        font-size: 16px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;

        .jobs-apply-questions-text-question-html {
            display: block;
            font-size: 16px;
            font-family: $fontRegular;
            margin-left: 4px;
            
            color: var(--text-color);

            p{
                margin-bottom: 0;
            }
        }

        .jobs-apply-questions-text-question-html-error {
            display: flex;
            font-size: 16px;
            font-family: $fontMedium;
            margin-left: 4px;
            color: var(--red-color);
        }
    }

    .jobs-apply-grid-control {
        margin-top: 48px;
        display: grid;
        width: 80%;
        z-index: 3;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 100%;

        @include media-breakpoint-down(lg){
            width: 100%;
            margin-top: 12px;
        }



        .jobs-apply-item-checkbox-flex-control {
            display: flex;

            .jobs-apply-item-checkbox {
                margin-right: 16px;
                font-size: 16px;
                font-family: $fontRegular;
            }

            .jobs-apply-item-checkbox-error {
                color: var(--red-color);
            }

            .jobs-apply-item-checkbox-error>.form-check-input {
                border-color: var(--red-color) !important;
                border-width: 2px;

            }
        }

        .jobs-apply-item-main-box {
            border-radius: 24px;
            background-color: white;
            padding: 24px 16px;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            z-index: 2;
            overflow: auto;
            box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.06), 0px 8px 22px -6px rgba(24, 39, 75, 0.08);
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(lg){
                width: 100%;
                padding: 14px 12px;
            }
            @include media-breakpoint-down(md){
                width: 100%;
                padding: 14px 12px;
            }
            .jobs-apply-header {
                font-size: 20px;
                font-family: $fontMedium;
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 36px;
            }

            .jobs-apply-main-item-control {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 8px;
                border-radius: 8px;
                padding: 8px 24px;
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(lg){
                    width: 100%;
                    padding: 4px 8px;
                }
                @include media-breakpoint-down(md){
                    width: 100%;
                    padding: 4px 8px;
                }
            }

            .jobs-apply-main-item-expand-control {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;


                .jobs-apply-main-item-expand-divider {
                    margin-top: 12px;
                    margin-bottom: 12px;
                    width: 100%;
                    height: 1px;
                    background-color: var(--border-input);
                }

                .jobs-apply-main-item-expand-grid {
                    display: grid;
                    grid-template-columns: 50% 50%;

                    @include media-breakpoint-down(md){
                        grid-template-columns: 100%;
                    }


                }

                .jobs-apply-main-item-expand-flex-control-all-item {
                    display: contents;

                    @include media-breakpoint-down(md){
                        display: flex;
                        flex-direction: column;
                    }
                }

                .jobs-apply-main-item-expand-grid-item {
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    margin-top: 16px;

                    @include media-breakpoint-down(md){
                        margin-top: 6px;
                        width: 100%;
                    }
                }

                .jobs-apply-main-item-expand-grid-item-full {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                .jobs-apply-main-item-expand-grid-item-half {
                    display: grid;
                    grid-template-columns: 50% 50%;
                }

                .jobs-apply-main-item-expand-title {
                    font-size: 15px;
                    font-family: $fontRegular;
                    color: var(--text-soft-color);
                    margin-bottom: 8px;
                }

                .jobs-apply-section-header-control{
                    display: inline;
                    margin-top: 12px;
                    width: 100%;
                    margin-bottom: 12px;
                    grid-column: 1 / span 2; 
                    .MuiDivider-root::before{
                        display: none;
                    }
                    .MuiDivider-root::after{
                        margin-right: 12px;
                    }
                    .jobs-apply-section-header-text{
                        font-size: 16px;
                        color: var(--text-color);
                        font-family: $fontMedium;
                        margin-top: auto;
                        margin-left: -8px;
                        margin-bottom: auto;
                        width: fit-content;
                    }

                    
                }

                .jobs-apply-main-item-divider-with-title {
                    width: 90%;
                    font-size: 15px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                    margin-bottom: 8px;
                }

                .jobs-apply-main-item-expand-sub-title {
                    margin-top: 6px;
                    font-size: 14px;
                    font-family: $fontRegular;
                    color: var(--text-color);
                }

                .jobs-apply-main-item-expand-link {
                    color: var(--link-color);
                    font-size: 14px;
                    text-decoration: underline;
                    cursor: pointer;
                    font-family: $fontRegular;
                    margin-left: 8px;

                    &:hover {
                        font-family: $fontMedium;
                    }
                }

                .jobs-apply-main-item-profile-img-border {
                    border-radius: 8px;
                    width: 50%;
                    height: auto;
                    padding: 24px;
                    box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.06), 0px 8px 22px -6px rgba(24, 39, 75, 0.08);
                    display: flex;
                    flex-direction: column;

                    @include media-breakpoint-down(md){
                        margin-bottom: 16px;
                    }
                }

                .jobs-apply-main-item-profile-button{
                    text-decoration: underline;
                    cursor: pointer;
                    font-size: 12px;
                    font-family: $fontMedium;
                    margin-top: 12px;
                    color: var(--text-blue-color);
                }

                .jobs-apply-main-item-profile-img {
                    width: 100%;
                    height: auto;
                    border-radius: 50%;
                    margin: auto;
                    object-fit: contain;
                }
            }

            .jobs-apply-main-item-step-control {
                cursor: pointer;
                display: flex;

                .jobs-apply-main-item-step {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    border: none;
                    padding: 0px;
                    background-color: var(--primary-color);

                    .jobs-apply-main-item-step-text {
                        font-size: 16px;
                        font-family: $fontBold;
                        color: var(--text-white-color);
                        margin: auto;
                    }
                }

                .jobs-apply-main-item-step-button-control {
                    margin-left: auto;
                    display: flex;

                    .jobs-apply-main-item-step-button {
                        font-size: 16px;
                        color: var(--button-primary-color);
                        font-family: $fontMedium;
                        margin-top: auto;
                        margin-bottom: auto;
                        cursor: pointer;
                    }
                }

                .jobs-apply-main-item-step-header {
                    font-size: 16px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                    margin-left: 16px;
                    margin-top: auto;
                    margin-bottom: auto;
                }


            }
        }

        .jobs-apply-detail-control {
            display: grid;
            grid-template-columns: 20% 80%;
            margin-bottom: 6px;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
                margin-bottom: 16px;
            }
        }

        .jobs-apply-detail-control-full {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
        }

        .jobs-apply-title {
            font-size: 16px;
            font-family: $fontRegular;
            font-weight: 300;
            color: var(--text-color);
            margin-left: 8px;
            overflow-wrap: break-word;

            @include media-breakpoint-down(md){
                margin-left: 0px;
                font-size: 14px;
                font-family: $fontMedium;
            }
        }

        .jobs-apply-desc {
            font-size: 16px;
            font-family: $fontRegular;
            color: var(--text-soft-color);

            @include media-breakpoint-down(md){
                font-size: 12px;
            }
        }

        .jobs-apply-item-divider {
            width: 100%;
            height: 2px;
            margin-top: 12px;
            margin-bottom: 12px;
            opacity: 0.5;
            background-color: var(--border-input);
        }

        .jobs-apply-item-form-input {
            width: 100%;
            height: 41px;
            border-radius: 6px;
            color: var(--text-color);
            border: 1px solid var(--border-input);
            font-size: 14px;
            font-family: $fontRegular;
            .dropdown-menu.show{
                max-height: 400px !important;
            }
            
        }

        .jobs-apply-item-form-input-error {
            border: 2px solid var(--red-color) !important;
        }

        .jobs-apply-button-default {
            background-color: var(--primary-color);
            margin-top: 12px;
            border: none;
            width: 100%;
            font-family: $fontMedium;
            height: 41px;
            font-size: 14px;
            color: var(--text-white-color);
            border-radius: 8px;
        }
    }

    .jobs-apply-button-save {
        background-color: var(--primary-color);
        margin-top: 24px;
        border: none;
        min-width: 100px;
        width: min-content;
        font-family: $fontMedium;
        height: 41px;
        font-size: 14px;
        padding-left: 24px;
        padding-right: 24px;
        color: var(--text-white-color);
        border-radius: 8px;

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }

    .my-info-item-control-error {
        border: 2px solid var(--red-color);
    }

    .job-apply-submit-application-button-disabled-control {
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        .job-apply-submit-application-button-disabled-text {
            font-size: 14px;
            color: var(--red-color);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-family: $fontMedium;
            margin-bottom: 12px;
        }

        .job-apply-submit-application-button-disabled {
            margin-left: auto;
            margin-right: auto;
            background-color: var(--border-input);
            border: none;
            width: 300px;
            font-family: $fontMedium;
            height: 41px;
            font-size: 14px;
            color: var(--text-white-color);
            border-radius: 8px;

            &:hover {
                background-color: var(--border-input);
            }
        }
    }

    .job-apply-submit-application-button {
        margin-left: auto;
        margin-right: auto;
        background-color: var(--primary-color);
        margin-top: 24px;
        border: none;
        width: 300px;
        font-family: $fontMedium;
        height: 41px;
        font-size: 14px;
        color: var(--text-white-color);
        border-radius: 8px;


        &:hover {
            background-color: var(--primary-color);
        }
    }

    .my-info-item-control {
        border-radius: 8px;
        background-color: white;
        padding: 8px;
        width: 100%;
        overflow: auto;
        margin-bottom: 8px;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .my-info-item-header {
            font-size: 15px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-top: auto;
            margin-bottom: auto;
        }
        .my-info-item-header-button-control{
            display: flex;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
            .my-info-item-header-button-icon{
                font-size: 20px;
                color: var(--primary-color);
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 6px;
            }
        }
        .my-info-item-header-button {
            font-size: 13px;
            color: var(--button-primary-color);
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
        }

        .my-info-item-divider {
            width: 100%;
            height: 2px;
            margin-top: 12px;
            margin-bottom: 12px;
            opacity: 0.5;
            background-color: var(--border-input);
        }


        .my-info-item-icon {
            margin-bottom: auto;
            width: 14px;
            height: 14px;
            color: var(--primary-color);
            object-fit: contain;
            margin-right: 6px;
        }

        .my-info-item-desc {

            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            margin-top: 6px;
            font-family: $fontRegular;
            color: var(--text-gray-color);
        }

        .my-info-item-desc-value {
            margin-left: 6px;
            display: flex;
            font-size: 13px;
            word-break: break-all;
            margin-top: auto;
            margin-bottom: auto;
            font-family: $fontMedium;
            color: var(--text-color);
        }

        .my-info-timeline-control {
            display: grid;
            grid-template-columns: 5% 95%;

            .my-info-timeline-line-control {
                display: flex;
                flex-direction: column;
                padding-top: 4px;

                .my-info-timeline-dot {
                    width: 10px;
                    height: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 50%;
                    background-color: var(--primary-color);
                }

                .my-info-timeline-line-vertical {
                    margin-top: 4px;
                    margin-bottom: 2px;
                    width: 2px;
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    opacity: 0.5;
                    background-color: var(--border-input);
                }
            }

            .my-info-timeline-desc-control {

                display: flex;
                flex-direction: column;
                padding-left: 16px;
                padding-right: 16px;
                margin-bottom: 16px;

                @include media-breakpoint-down(md){
                    padding-right: 0px;
                }

                .my-info-timeline-desc-header {
                    font-size: 13px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                }

               
            }

        }
        .my-info-timeline-desc-info-control {
            margin-top: 4px;
            margin-left: 8px;
            display: flex;
            flex-direction: column;

            li{
                color: var(--primary-color);
            }

            .my-info-timeline-desc-info-title {
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--text-soft-color);
                margin-right: 4px;
            }

            .my-info-timeline-desc-info-desc {
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--text-color);
                padding-left: 4px;
            }
        }
        .no-info-control {
            min-height: auto;
            width: 100%;
            display: flex;
            padding-top: 16px;

            .no-info-control-flex {
                display: flex;
                flex-direction: column;
            }

            .no-info-control-text {
                font-size: 16px;
                font-family: $fontRegular;
                color: var(--text-gray-color);
            }

            .no-info-control-add-button {
                margin-top: 16px;
                
                height: 41px;
                border: none;
                color: var(--text-color);
                border: 1px solid var(--button-primary-color);
                font-family: $fontMedium;
                background-color: white;
                font-size: 12px;
                padding-left: 24px;
                padding-right: 24px;

               &:active{
                    background-color: white !important;
                    color: var(--text-color);
                }
            }
        }
    }

}