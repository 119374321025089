@import "../../../App.scss";

.interview-history-main{
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .interview-history-control {
        display: flex;
        flex-direction: column;
        width: 100%;

        .interview-history-header-control {
            display: flex;
            width: 100%;

            .interview-history-header {
                font-size: 25px;
                color: var(--text-color);
                font-family: $fontMedium;
                margin-top: auto;
                margin-bottom: auto;
            }

            .interview-history-add-button {
                width: auto;
                height: 46px;
                margin-left: 16px;
                background-color: white;
                border: 2px solid var(--primary-color);
                border-radius: 8px;
                color: var(--primary-color);
                font-family: $fontMedium;
                font-size: 16px;

                &:hover {
                    background-color: var(--primary-color);
                    color: white;
                }
            }
        }
    }
    .interview-history-table-list-control{

        display: flex;
        margin-top: 24px;
        border-radius: 8px;

        .interview-history-table-list-row-control{
            height: 51px;
            min-height: 51px;
        }

        .interview-history-table-list-header{
            font-size: 14px;
            font-family: $fontMedium !important;
            color: var(--text-color);
        }
        .interview-history-table-list-desc{
            font-size: 14px;
            font-family: $fontRegular;
            color: var(--text-color);
        }
        .interview-history-table-list-button{

            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
            color: var(--text-white-color);
            border-radius: 8px;
            font-size: 12px;
            font-family: $fontMedium;
            margin-right: 12px;
            &:hover{
                background-color: var(--primary-color);
                color: var(--text-white-color);
                border:none;
            }
        }
    }

    .interview-history-no-list-main{
        display: flex;
        height: 70vh;
        width: 100%;

        .interview-history-no-list-control{
            margin: auto;
            display: flex;
            flex-direction: column;

            .interview-history-no-list-text{
                font-size: 14px;
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
                font-family: $fontRegular;
            }
        }
    }
    .interview-history-start-button{

        background-color: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        border-radius: 8px;
        font-size: 14px;
        font-family: $fontMedium;
        margin-left: 14px;

        &:hover{
            background-color: var(--primary-color);
            color: var(--text-white-color);
            border:none;
        }
    }

}