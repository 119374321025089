@import "../../../App.scss";

.view-profile-main {
    width: 100vw;
    height: 100vh;
    display: flex;

    .view-profile-main-control {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    .view-profile-error-control {
        width: 100vw;
        height: 100vh;
        display: flex;

        .view-profile-error-main {
            margin: auto;
            flex-direction: column;
            display: flex;
        }

        .view-profile-error-img {
            width: 40%;
            height: auto;
            margin: auto;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }

        .view-profile-error-text{
            font-size: 20px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-top: 12px;
            margin-left: auto;
            margin-right: auto;
        }
    }


}