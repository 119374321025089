@import "../App.scss";


.crop-modal{
    display: flex;
    flex-direction: column;
    width: 100%;

    .reactEasyCrop_Container{
        position: relative !important;
        width: 400px;
        height: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .crop-uploading-control{
        display: flex;
        flex-direction: column;
        padding: 36px;

        .crop-uploading-progress{
            width: 40px;
            height: 40px;
            margin-left: auto;
            margin-right: auto;

        }

        .crop-uploading-progress-text{
            font-size: 16px;
            color: var(--text-color);
            margin-left: auto;
            margin-right: auto;
            font-family: $fontRegular;
            margin-top: 16px;
        }
    }

    .crop-modal-img{
        width: 100%;
        height: 100%;
    }

    .crop-modal-preview{
        border: none !important;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        object-fit: contain;
    }

    .crop-modal-button-save{
        margin-top: 16px;
        background-color: var(--button-primary-color);
        font-size: 14px;
        min-width: 200px;
        border-radius: 8px;
        font-family: $fontMedium;
        color: white;
        margin-left: auto;
        margin-right: auto;

        &:hover{
            background-color: var(--button-primary-color);
            border: none;
        }
    }
}