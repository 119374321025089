@import "../../../App.scss";


.cover-letter-main {
    display: flex;
    flex-direction: column;
    padding: 24px;


    .cover-letter-control {
        display: flex;
        flex-direction: column;

        .cover-letter-text-title {
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--text-color);
        }

        .cover-letter-save-button {
            width: 100%;
            margin-top: 36px;
            height: 41px;
            margin-left: auto;
            margin-right: auto;
            border: none;
            border-radius: 8px;
            background-color: var(--button-primary-color);
            color: white;
            font-family: $fontMedium;
            font-size: 13px;
        }

        .cover-letter-cancel-button {
            width: 100%;
            margin-top: 6px;
            height: 41px;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid var(--button-primary-color);
            border-radius: 8px;
            background-color: white;
            color: var(--button-primary-color);
            font-family: $fontMedium;
            font-size: 13px;
        }

        .cover-letter-form-control {
            display: flex;
            flex-direction: column;

            .cover-letter-form-text {
                font-size: 13px;
                font-family: $fontMedium;
                color: var(--text-color);
            }

            .cover-letter-form-input {
                padding: 12px;
                width: 100%;
                border: 1px solid var(--border-input);
                border-radius: 12px;
                margin-top: 12px;
                min-height: 41px;
                font-size: 14px;
                font-family: $fontRegular;
            }

        }
        
        .cover-letter-loading-control{
            display: flex;
            flex-direction: column;
            min-height: 300px;

            .cover-letter-loading-main{
                display: flex;
                flex-direction: column;
                margin: auto;

                .cover-letter-loading-progress{
                    width: 70px;
                    height: 70px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }
                .cover-letter-loading-text{
                    font-size: 14px;
                    color: var(--text-color);
                    font-family: $fontMedium;
                    margin-left: auto;
                    margin-top: 24px;
                    margin-right: auto;
                    animation: blink 1s linear infinite;
                }
            }
        }
    }
    @keyframes blink {
        50% { 
          opacity: 0; 
        }
      }
}