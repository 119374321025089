@import "../../../App.scss";


.iframe-question{
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border-radius: 8px;
}