@import "../../../../App.scss";


.template-1-control {
    display: table;
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    word-wrap: break-word;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    .template-1-cms-background {

        background-color: white;
    }

    .template-1-form-control {
        width: 210mm;
        display: flex;
        flex-direction: column;

        .template-1-footer-control {
            height: 50px;
            width: 100%;
        }

        .template-1-desc-control {
            display: grid;
            grid-template-columns: 60mm 150mm;
            padding: 24px;
            background-color: white;

            @include media-breakpoint-down(md){
                grid-template-columns: 60mm 150mm;
            }

            .template-1-desc-right-control {
                display: flex;
                flex-direction: column;
                padding: 4px 24px 4px 4px;

                .template-1-desc-right-section-control {
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    margin-bottom: 16px;
                    padding-left: 24px;

                    .template-1-desc-title {
                        font-size: 18px;
                        color: var(--text-color);
                        font-family: $fontMedium;
                        word-wrap: break-word;
                        word-break: break-word;
                    }

                    .template-1-desc-content {
                        font-size: 12px;
                        color: var(--text-color);
                        margin-top: 16px;
                        word-wrap: break-word;
                        word-break: break-word;
                        font-family: $fontRegular;
                    }



                }
            }



            .template-1-desc-left-control {
                display: flex;
                flex-direction: column;
                padding: 4px 24px 4px 4px;



                .template-1-desc-left-section-control {
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    margin-bottom: 16px;


                    .template-1-desc-title {
                        font-size: 18px;
                        color: var(--text-color);
                        font-family: $fontMedium;
                        word-wrap: break-word;
                        word-break: break-word;
                    }

                    .template-1-desc-divider {
                        width: 100%;
                        height: 3px;
                        background-color: var(--border-input);
                        margin-bottom: 16px;
                    }

                    .template-1-desc-content {
                        font-size: 12px;
                        color: var(--text-color);
                        word-wrap: break-word;
                        word-break: break-word;
                        font-family: $fontRegular;

                    }

                    .template-1-desc-content>ul {
                        padding-left: 20px !important;

                    }

                    .template-1-desc-content>ul>li>ul {
                        padding-left: 10px !important;
                        padding-bottom: 8px !important;
                    }
                }
            }

        }

        .template-1-profile-control {
            display: grid;
            flex-direction: column;
            min-height: 150px;
            background-color: black;
            grid-template-columns: 60% 40%;
            padding: 24px 12px;

            .template-1-profile-text-control {
                display: flex;
                flex-direction: column;
            }

            .template-1-name {
                font-size: 36px;
                font-family: $fontMedium;
                color: white;
                word-wrap: break-word;
            }

            .template-1-position {
                font-size: 22px;
                font-family: $fontRegular;
                color: white;
                margin-right: 36px;
                word-wrap: break-word;
            }

            .template-1-cover-letter {
                font-size: 13px;
                font-family: $fontRegular;
                color: white;
                margin-right: 36px;
                word-wrap: break-word;
            }

            .template-1-cover-contact-control {
                display: flex;
                flex-direction: column;
                margin-top: auto;
            }

            .template-1-cover-contact {
                font-size: 12px;
                font-family: $fontRegular;
                color: white;
                margin-right: 0px;
                text-align: right;
                margin-top: auto;
                margin-bottom: auto;
                word-wrap: break-word;
            }
        }



    }
}