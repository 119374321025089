@import "../App.scss";


.file-limit-modal{
    .modal-dialog {
        max-width: 50% !important;
        width: 50% !important;
        min-height: 40vh !important;
        max-height: 90vh !important;
        margin-bottom: 5% !important;

        @media (min-width: 1200px) {

            width: 50% !important;
            max-width: 50% !important;
        }

        @include media-breakpoint-down(md) {
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content {
        width: 100%;
        min-height: 40vh !important;
        max-height: 90vh !important;
    }
    .file-limit-content{
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .file-limit-container{
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .file-limit-header{
        font-size: 18px;
        font-family: $fontMedium;
        margin-top: 24px;
    }

    .file-limit-icon{
        width: 50px;
        height: 50px;
        color: red;
        margin: auto;
    }

    .file-limit-button{
        margin-top: 24px;
        background-color: var(--button-primary-color);
        border-radius: 8px;
        color: var(--text-white-color);
        font-size: 14px;
        border: none;

        &:hover, &:focus{
            background-color: var(--button-primary-color);
            border: none;
        }
    }
}