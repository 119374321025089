@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fontBold: "LINESeedSansTH_He";
$fontRegular: "LINESeedSansTH_Rg";
$fontMedium: "LINESeedSansTH_Bd";
$fontLight: "LINESeedSansTH_Th";



a{
    cursor: pointer;
    color: #64b5fc;
    margin-top: 16px;
    font-family: $fontMedium;
    font-size: 14px;
    text-decoration: underline;
}
body{
    font-family: $fontRegular !important;
    // background-color: #edf0f5;
    scrollbar-width: none;
    background-image: url('./assets/images/gradient-bg.svg');
    background-size: cover;  /* or contain depending on what you need */
    background-repeat: no-repeat;
    background-position: top center;
}

.bg-circle-main{
    width: 500px;
    height: auto;
    object-fit: contain;
    position: fixed;
    z-index: 0;
    bottom: 0;
    margin-top: auto;
    right: 0;
    margin-left: auto;
}

.MuiFormLabel-root{
    font-family: $fontRegular !important;
}
.MuiChip-label{
    font-family: $fontMedium !important;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.searching-load{
    width: 200px;
    height: 200px;
    object-fit: contain;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.bg-light{
    background-color: var(--header-color) !important;
}

.searching-text{
    font-size: 16px;
    font-family: $fontMedium;
    margin-top: 4px;
}
.button-default-blue{
    background-color: var(--primary-color);
    color: var(--text-white-color);
    height: 46px;
    font-family: $fontMedium;
    font-size: 16px;
    min-width: 100px;
    border: none;

    &:hover{
        background-color: var(--primary-color);
    }
}
.button-default{
    background-color: var(--secondary-color);
    color: var(--text-color);
    height: 46px;
    font-family: $fontMedium;
    font-size: 16px;
    min-width: 100px;
    border: var(--border-line) 2px solid;
    border-radius: 8px;

    &:hover{
        background-color: var(--primary-color);
    }
}

.main-control{
    width: 100vw;
    height: 100vh;
    display: flex;

    @include media-breakpoint-down(md){
        display: flex;
        flex-direction: column;
    }
}

.link-default{
    font-size: 16px;
    font-family: $fontRegular;
    color: var(--button-primary-color);
    text-decoration: underline;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

button{

    &:active{
        background-color: var(--button-primary-color) !important;
    }
}

@font-face {
    font-family: $fontBold;
    src: local("LINESeedSansTH_He"), url(../public/fonts/LINESeedSansTH_He.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("LINESeedSansTH_Bd"), url(../public/fonts/LINESeedSansTH_Bd.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("LINESeedSansTH_Rg"), url(../public/fonts/LINESeedSansTH_Rg.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("LINESeedSansTH_Th"), url(../public/fonts/LINESeedSansTH_Th.ttf) format("truetype");
}
