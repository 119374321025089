@import "../../../../App.scss";

.skills-control{
    display: flex;
    flex-direction: column;
    margin-top: 12px;

  

    .skill-title-control{
        display: flex;
    }

    .skills-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;
      
    }

    .skills-tag-title{
        margin-top: 24px;
        font-size: 14px;
        color: var(--text-gray-color);
        font-family: $fontRegular;
        margin-bottom: 6px;
    }

    .skills-tag-border{
        border: 1px solid var(--border-input);
        border-radius: 8px;
        padding: 6px 16px;
    }

    .skills-tag-control{
        display: flex;
      
        flex-wrap: wrap;

        .skills-tag-item{
            margin-top: 4px;
            margin-bottom: 4px;
            cursor: pointer;
            margin-left: 2px;
            margin-right: 2px;
            width: auto;
            padding: 4px 16px;
            border-radius: 8px;
            border: 1px solid var(--primary-color);
            font-size: 14px;
            color: var(--primary-color);
            font-family: $fontMedium;
            display: flex;

            &:hover{
                background-color: var(--primary-color);
                color: white;
            }
        }

    }

    .skills-form-control{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 36px;
        .skills-form-group-control{
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            .skills-form-group-title{
                font-size: 14px;
                color: var(--text-color);
                font-family: $fontMedium;
            }

            .skills-form-input{
                height: 41px;
                width: 100%;
                font-size: 14px;
                font-family: $fontRegular;
            }
        }

        .skills-form-item-control{
            display: grid;
            grid-template-columns: 70% 30%;
        }

        .skills-form-item-control-item{
            display: flex;
            flex-direction: column;
        }

        .skill-form-button-remove{
            border: none;
            height: 41px;
            width: 80%;
            background-color: var(--red-color);
            font-size: 14px;
            color: var(--text-white-color);
            font-family: $fontMedium;
            border-radius: 8px;
            margin-left: auto;
            margin-right: auto;
        }
        
    }

    .skills-submit-button{
        border: none;
        height: 41px;
       
        background-color: var(--primary-color);
        font-size: 14px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;
    }
    .skills-add-button{
        margin-left: auto;
        border: 1px solid var(--primary-color);
        width: 30%;
        height: 41px;
        background-color: var(--text-white-color);
        font-size: 13px;
        color: var(--primary-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: auto;

        &:hover{
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }

    }
   
}