@import "../../../../App.scss";

.introduction-control{
    display: flex;
    flex-direction: column;

  

    .introduction-title-control{
        display: flex;
        margin-bottom: 24px;
    }

    .introduction-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;
   

      
    }
    .introduction-suggest{
        font-size: 11px;
        font-family: $fontRegular;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;
      
    }
    .introduction-form-text-area{
        font-size: 14px;
        font-family: $fontRegular;
        padding: 15px;
    }
    .introduction-item-edit-button-control{
        display: flex;
        width: 50%;
    }

    .introduction-item-edit-button-cancel {
        width: 50%;
        height: 31px;
        background-color: var(--red-color);
        border: none;
        margin-left: 6px;
        color: white;
        margin-top: 16px;
        font-family: $fontMedium;
        font-size: 12px;

        &:hover{
            background-color: var(--red-color);
        }

    }

    .introduction-item-text-error {
        font-size: 11px;
        color: var(--red-color);
        font-family: $fontRegular;
        margin-top: 2px;
    }

    .introduction-item-edit-button {
        width: 50%;
        height: 31px;
        background-color: var(--button-primary-color);
        border: none;
        color: var(--text-white-color);
        margin-top: 16px;
        font-family: $fontMedium;
        font-size: 12px;

        &:hover {
            background-color: var(--button-primary-color);
            color: white;
            border: none;
        }

    }
}