@import "../../App.scss";


.login-main {
    width: 100vw;
    min-height: 100vh;
    display: flex;

    @include media-breakpoint-down(md){
        flex-direction: column;
    }

    .login-main-control {
        padding-left: 5%;
        padding-right: 5%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .login-main-logo {
            width: auto;
            height: 70px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md){
               height: 40px;
               margin-top: 46px;
            }
        }

        .login-choose-control{
            display: grid;
            grid-template-columns: 50% 50%;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }

            .login-logo-choose{
                width: 80%;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }
        

            .login-choose-border-control{
                cursor: pointer;
                border: 2px solid var(--primary-color);
                padding: 24px;
                color: var(--primary-color);
                display: flex;
                margin-left: auto;
                margin-right: auto;
                border-radius: 12px;
                flex-direction: column;
                width: 80%;
                min-height: 400px;
                @include media-breakpoint-down(md){
                    width: 100%;
                    margin-bottom: 24px;
                }
                &:hover{
                    background-color: var(--primary-color);
                    box-shadow: var(--shadow-box);
                }

                &:hover .login-choose-title {
                    color: white;
                }

                &:hover .login-choose-title-logo{
                    color: white;
                }

                &:hover .login-choose-list-control > .login-choose-list-title{
                    color: white;
                }

                &:hover .login-choose-list-control > .login-choose-list-icon{
                    color: white;
                }

                &:hover .login-choose-click-title{
                    color: white;
                    text-decoration: underline;
                }

                .login-choose-title{
                    text-align: center;
                    font-size: 24px;
                    color: var(--primary-color);
                    font-family: $fontMedium;
                }
                .login-choose-title-logo{
                    text-align: center;
                    font-size: 48px;
                    color: var(--primary-color);
                    font-family: $fontMedium;
                }

                .login-choose-click-title{
                    font-size: 14px;
                    color: var(--primary-color);
                    font-family: $fontRegular;
                    text-align: center;
                    margin-top: auto;
                }

                .login-choose-list-control{
                    margin-top: 48px;
                    display: grid;
                    grid-template-columns: 10% 90%;

                    .login-choose-list-title{
                        color: var(--primary-color);
                        font-size: 14px;
                        font-family: $fontRegular;
                    }

                    .login-choose-list-icon{
                        font-size: 15px;
                        color: var(--primary-color);
                    }

                    .login-choose-list-icon-red{
                        font-size: 15px;
                        color: var(--red-color);
                    }
                }

              
            }
        }

        .login-main-button {
            min-height: 46px;
            width: 30%;
            margin-top: 15%;
            background-color: transparent;
            border: 2px solid var(--button-primary-color);
            color: var(--button-primary-color);
            font-size: 16px;
            border-radius: 8px;
            font-family: $fontMedium;

            &:hover{
                background-color: var(--primary-color);
                color: white;
                box-shadow: var(--shadow-box);
            }

        }
    }
}