@import "../../../App.scss";



.drop-resume-main {

    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 2;


    @include media-breakpoint-down(md) {
        height: auto;
    }

    .MuiOutlinedInput-root{
        border-radius: 8px;
        border:none;

        &:focus{
            border-radius: 8px;
            border:none !important;
        }
    }
    .MuiInputBase-input{
        color: var(--text-color);
        border-radius: 8px;
        border:none;

        &:focus{
            border-radius: 8px;
            border:none !important;
        }

        &::placeholder{
            font-family: $fontRegular !important;
            color: var(--text-color) !important;
        }
    }

    .drop-resume-complete-control{
        width: 100%;
        height: 40vh;
        display: flex;

        .drop-resume-complete-main{
            margin: auto;
            display: flex;
            flex-direction: column;
        }

        .drop-resume-complete-icon{
            width: 80px;
            height: 80px;
            margin-left: auto;
            margin-right: auto;
            color: var(--primary-color);
        }

        .drop-resume-complete-text{
            font-size: 18px;
            font-family: $fontMedium;
            color: var(--text-color);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
        }

        .drop-resume-complete-text-send-more{
            cursor: pointer;
            font-size: 14px;
            font-family: $fontRegular;
            text-decoration: underline;
            color: var(--text-color);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 60px;
        }
    }
    .drop-resume-overlay-black {
        background-color: black;
        opacity: 0.6;
        width: 100%;
        min-height: 100vh;
        height: max-content;
        position: fixed;
        z-index: 2;

        @include media-breakpoint-down(md) {
            opacity: 0.8;
        }
    }

    .drop-resume-loading-control{
        min-height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 9;
    
        .drop-resume-loading-item{
            margin: auto;
            display: flex;
            flex-direction: column;

            .drop-resume-loading-progress{
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .drop-resume-loading-text{
                margin-top: 24px;
                font-size: 18px;
                color: var(--text-white-color);
                font-family: $fontMedium;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .drop-resume-cover-header-control {

        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(lg) {
            min-height: 800px;

        }

        @include media-breakpoint-down(md) {
            min-height: 700px;
            height: auto;

        }

      
    }

    .drop-resume-control {
        margin-top: 36px;
        border-radius: 34px;
        background: white;
        box-shadow: 0px 5.1px 10.2px -5.1px var(--primary-color), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 36px;
        z-index: 3;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;

        @include media-breakpoint-down(md){
            width: 95%;
        }
    }

    .drop-resume-title-control{
        display: flex;
        font-size: 32px;
        font-family: $fontMedium;

        .drop-resume-title-white{
            color: var(--text-blue-color);
        }
        .drop-resume-title-orange{
            color: var(--text-blue-color);
        }
    }

    .drop-resume-desc-text{
        margin-top: 4px;
        font-size: 16px;
        font-family: $fontRegular;
        color: var(--text-blue-color);
    }

    .drop-resume-form-control {
        display: flex;
        width: 100%;
        margin-left: auto;
        flex-wrap: wrap;
        margin-right: auto;
        margin-bottom: 16px;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }
    }

    .drop-resume-form-control-full {
        grid-template-columns: 100% !important;
    }

    .drop-resume-field-item-33 {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @include media-breakpoint-down(xl){
            width: 100%;
        }
        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .drop-resume-field-item-50 {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        @include media-breakpoint-down(xl){
            width: 100%;
        }
        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .drop-resume-upload-file {
        text-align: left;
        border-radius: 8px;
        background-color: white;
        border: 1px solid var(--border-input);
        padding-left: 16px;
        min-height: 47px;
        display: flex;
        width: 90%;
       
        @include media-breakpoint-down(xl){
            width: 100%;
        }
    }
    .drop-resume-upload-file-text{
        margin-left: 16px;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
        font-family: $fontRegular;
        color: var(--text-color);
        white-space: nowrap; /* Prevents the text from wrapping to a new line */
        overflow: hidden; /* Hides any overflow content */
        text-overflow: ellipsis; /* Adds an ellipsis to signify clipped text */
    }
    .drop-resume-upload-file-button {
        background-color: var(--primary-color);
        border-radius: 20px;
        border: none;
        font-size: 13px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 16px;
        padding-right: 16px;
        height: 35px;
        min-width: 120px;
        width: auto;
        &:hover {
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }

        &:active {
            background-color: var(--primary-color) !important;
        }
    }


    .drop-resume-submit-button {
        background-color: var(--button-primary-color);
        border-radius: 20px;
        border: none;
        font-size: 16px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        margin-top: 48px;
        padding-left: 36px;
        padding-right: 36px;
        height: 51px;
        width: 300px;
        &:hover {
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }

        &:active {
            background-color: var(--primary-color) !important;
        }

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .drop-resume-title {
        font-size: 16px;
        font-family: $fontRegular;
        color: var(--text-blue-color);
        margin-bottom: 4px;
    }

    .drop-resume-error {
        font-size: 12px;
        font-family: $fontRegular;
        color: var(--red-color);
    }

    .drop-resume-input {
        width: 90%;
        border-radius: 8px;
        background-color: white;
        font-size: 14px;
        color: var(--text-color);
        font-family: $fontRegular;

        &::placeholder{
            color: var(--text-color);
            font-family: $fontRegular !important;
        }

        @include media-breakpoint-down(xl){
            width: 100%;
        }
    }

    .drop-resume-text-area {
        padding: 16px 16px;
        width: 90%;
        border-radius: 8px;
        background-color: white;
        border-color: var(--border-input) !important;
        font-size: 14px;
        color: var(--text-color);
        font-family: $fontRegular;

        @include media-breakpoint-down(xl){
            width: 100%;
        }

        &::placeholder{
            color: var(--border-input);
        }
    }

    .drop-resume-button-submit {
        border-radius: 8px;
        background-color: var(--primary-color);
        color: var(--text-white-color);
        border: 1px solid var(--primary-color);
        font-size: 14px;
        font-family: $fontMedium;
        margin-top: 16px;
        width: 95%;
        height: 51px;

        &:hover {
            background-color: var(--primary-color);
            box-shadow: var(--shadow-box);
        }

        &:active {
            background-color: var(--primary-color) !important;
        }
    }
}