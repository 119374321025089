@import "../../../App.scss";

.builder {
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 0px 36px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md){
        padding: 0px 0px;
    }
}

.builder-control {
    margin-top: 2%;
    display: grid;
    grid-template-columns: 100%;
   

    .builder-cms-background {
        height: auto;
        display: block;
        background-color: white;
        margin-top: 4px;
        width: 100%;
    }

 
    .ql-toolbar.ql-snow + .ql-container.ql-snow{
        min-height: 200px;
    }

    .builder-form-color-control{
        display: flex;

        .builder-form-color-value{
            width: 30px;
            height: 30px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 16px;
        }

       
    }
    .button-form-color-button{
        width: 100%;
        margin-top: 12px;
        background-color: var(--button-primary-color);
        height: 45px;
        font-size: 14px;
        border: none;

        &:hover{
            background-color: var(--button-primary-color);
        }
    }

    .builder-form-icon-ai{
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-left: 4px;
    }
    .builder-form-control {
        width: 100%;
        border-radius: 8px;
        box-shadow: 0px 0px 10px var(--border-input);
        display: grid;
        height: auto;
        background-color: var(--primary-color);
        padding: 12px;
        grid-template-columns: 30% 70%;

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }
        .builder-form-item-control-right{
            display: block;
            width: 100%;
            max-width: 100%;
            word-wrap: break-word;
            overflow: scroll;
            padding-top: 36px;
            min-height: 80vh;
            position: relative;

           
            .builder-form-right-page-control{
                width: 100%; // 793.7007874
                height: auto; // 1122.519685
                min-height: 800px;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
                overflow: scroll;
                @include media-breakpoint-down(md){
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform-origin: top left;
                    width: auto;
                    max-width: none;
                }
                @include media-breakpoint-down(sm){
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform-origin: top left;
                    width: auto;
                    max-width: none;
                }
            }
        }



        .builder-form-item-control {
            display: flex;
            flex-direction: column;
            padding: 24px 16px;

            @include media-breakpoint-down(md){
                padding: 12px 4px;
            }
           
          
            .builder-form-item-left{
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                background-color: white;
                padding: 12px;
                width: 95%;
                height: auto;
                margin-bottom: 14px;
            }


            
            .builder-form-item-title-control{
                display: flex;
               

                .builder-form-item-title{
                    font-size: 14px;
                    color: var(--primary-color);
                    font-family: $fontMedium;
                }

                .builder-form-item-edit{
                    font-size: 13px;
                    font-family: $fontMedium;
                    color: var(--primary-color);
                    margin-left: auto;
                    cursor: pointer;
                }
            }

            .builder-form-item-divider{
                margin-top: 6px;
                margin-bottom: 16px;
                width: 100%;
                height: 2px;
                background-color: var(--border-input);
            }

            .builder-form-item-value{
                font-size: 11px;
                color: var(--primary-color);
                font-family: $fontRegular;
                word-break: break-word;
            }

            .builder-form-item-input{
                width: 100%;
                min-height: 41px;
                border-radius: 4px;
                font-size: 14px;
                font-family: $fontRegular;
            }
           
        }


    }

    .builder-color-picker {
        width: 100px;
        height: 100px;
        margin-top: 4px;
        margin-bottom: 4px;

    }

    .builder-form-item-profile{
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .builder-preview-button {
        margin-top: 36px;
        width: 250px;
        height: 46px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--button-primary-color);
        border-radius: 16px;
        border: none;
        margin-bottom: 60px;
        &:hover{
            background-color: var(--button-primary-color);
        }
    }

    .builder-progress-circle{
       margin-top: auto;
       margin-bottom: auto;
    }

    .builder-required-red{
        font-family: $fontMedium;
        color: red;
    }
    .builder-required-red-gray{
        font-size: 9px;
        font-family: $fontRegular;
        color: var(--text-gray-color);
    }
}