@import "../../../App.scss";


.upload-resume-modal-main {
    padding: 4px;


    .upload-resume-modal-header {
        font-size: 18px;
        color: var(--text-color);
        font-family: $fontMedium;
    }
    .upload-resume-modal-header-sub {
        font-size: 12px;
        color: var(--text-gray-color);
        font-family: $fontRegular;
    }
    .upload-resume-modal-control {
        height: auto;
        width: 100%;
        border: 1px solid var(--border-input);
        border-radius: 8px;
        display: flex;
        padding: 36px;
        flex-direction: column;

        .upload-resume-modal-content-control {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 100%;
            height: auto;

            .upload-resume-modal-selected-control{
                width: 100%;
                display: grid;
                grid-template-columns: 20% 60% 20%;

                .upload-resume-modal-selected-icon{
                    font-size: 30px;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
                .upload-resume-modal-selected-icon-delete{
                    cursor: pointer;
                    font-size: 16px;
                    color: var(--red-color);
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: auto;
                }

                .upload-resume-modal-selected-filename-control{
                    display: flex;
                    flex-direction: column;
                }
            }

            .upload-resume-modal-desc {
                text-align: center;
                font-size: 13px;
                color: var(--text-gray-color);
                font-family: $fontRegular;
            }

            .upload-resume-modal-button{
               
                background-color: var(--primary-color);
                width: 60%;
                border: none;
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;
                height: 41px;
                font-size: 14px;
                color: var(--text-white-color);
                font-family: $fontMedium;
            }
        }




    }
    .upload-resume-modal-upload-button-disable{
        background-color: var(--disabled-color) !important;
    }
    .upload-resume-modal-upload-button{
        background-color: var(--primary-color);
        width: 100%;
        border: none;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
        height: 41px;
        font-size: 14px;
        color: var(--text-white-color);
        font-family: $fontMedium;
    }


}