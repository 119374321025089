@import "../../../../App.scss";

.MuiStack-root{
    padding-top: 8px !important;
    margin-bottom: 4px;
}

.MuiFormControlLabel-label{
    font-size: 13px !important;
    font-family: $fontMedium !important;
}

.MuiInputBase-input{
    font-family: $fontMedium !important;
}

.MuiOutlinedInput-input{
    font-size: 13px !important;
    font-family: $fontMedium !important;
    color: var(--text-color) !important;
}

.MuiSvgIcon-root{
    color: var(--text-color) !important;
}

.MuiInputLabel-root{
    font-size: 13px !important;
}
.MuiOutlinedInput-root {
    height: 48px !important;
}

.education-menu-item {
    font-family: $fontRegular !important;
    font-size: 14px !important;
    color: var(--text-color) !important;
}

.education-close-control{
    min-height: 30px;
    width: 100%;
    display: flex;
    padding: 4px 4px;

    .education-close-img{
        width: 15px;
        height: 15px;
        margin-left: auto;
        object-fit: contain;
        cursor: pointer;
    }
}

.profile-edit-loading-control{
    min-height: 300px;
    display: flex;
    width: 100%;

    .profile-edit-loading-flex{
        display: flex;
        flex-direction: column;
        margin: auto;

        .profile-edit-progress{
            width: 30px;
            height: 30px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 12px;
        }

        .profile-edit-text{
            margin-left: auto;
            margin-right: auto;
            font-size: 14px;
            color: var(--text-color);
            font-family: $fontRegular;
        }
    }
}

.education {
    display: flex;
    flex-direction: column;
    padding: 16px 8px;

    .experience-item:nth-child(6) {
        grid-row: span 2;
        /* Merge 2 rows */
    }
    
    .experience-current-work-control{
        margin-top: -10px;
    }
    .education-item-add-button {
        width: 100px;
        height: 31px;
        background-color: var(--text-white-color);
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        font-family: $fontMedium;
        font-size: 12px;

        &:hover{
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }
    }

    .education-header {
        font-size: 15px;
        color: var(--text-color);
        font-family: $fontMedium;
        margin-top: auto;
        margin-bottom: auto;
    }

    .experience-grid-edit{
        border: 2px solid var(--border-input);
        border-radius: 5px;
        padding: 18px 12px;
        margin-bottom: 12px;
        margin-top: 16px;
    }

    .experience-grid{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .education-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        border: 2px solid var(--border-input);
        border-radius: 5px;
        padding: 18px 12px;
        margin-bottom: 12px;
        margin-top: 16px;

    }


    .education-item-edit-button-cancel {
        min-width: 100px;
        width: min-content;
        height: 41px;
        border: 1px solid var(--button-primary-color);
        margin-left: 6px;
        background-color: white;
        color: var(--button-primary-color);
        margin-top: 16px;
        font-family: $fontMedium;
        font-size: 12px;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: 8px;
        margin-right: 8px;
        &:hover{
            background-color: var(--button-primary-color);
        }

    }

    .education-item-text-error {
        font-size: 11px;
        color: var(--red-color);
        font-family: $fontRegular;
        margin-top: 2px;
    }

    .education-item-edit-button-control{
        display: flex;
    }

    .education-item-edit-button {
        min-width: 100px;
        width: min-content;
        height: 41px;
        background-color: var(--button-primary-color);
        border: none;
        color: var(--text-white-color);
        margin-top: 16px;
        font-family: $fontMedium;
        font-size: 12px;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: 8px;
        margin-right: 8px;

        &:hover {
            background-color: var(--button-primary-color);
            color: white;
            border: none;
        }

    }

    .education-item-edit-button-disable {
        min-width: 100px;
        width: min-content;
        background-color: var(--disabled-color);
        border: none;
        color: var(--text-white-color);
        margin-top: 16px;
        font-family: $fontMedium;
        font-size: 12px;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: 8px;
        margin-right: 8px;
        &:hover {
            background-color: var(--disabled-color);
            color: white;
            border: none;
        }

    }

    .education-item-label {
        font-size: 13px;
        font-family: $fontRegular;
    }

    .education-item-input-select {
        font-size: 13px !important;
        font-family: $fontMedium !important;
        height: 48px;
    }
    .education-item {
        width: 95%;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }
    .education-textarea-control{
        font-size: 14px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--border-input);
        padding: 6px 12px;
    }
    .education-form-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: $fontMedium;
        margin-bottom: 6px;
    }
    .experience-item {
        width: 95%;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    .education-item-title {
        font-size: 13px;
        color: var(--text-color);
        font-family: $fontRegular;
    }

    .education-item-desc {
        font-size: 15px;
        color: var(--text-color);
        font-family: $fontMedium;
    }

    .education-item-input {
        height: 15px;
        font-family: $fontMedium !important;
        font-size: 13px;

        .MuiAutocomplete-root{
            width: 100% !important;
        }
    }

}