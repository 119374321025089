@import "../../App.scss";

.error-modal{
    display: flex;
    justify-content: center;

     
    .modal-dialog {
        max-width: 50% !important;
        width: 50% !important;
        height: 90vh !important;
        margin-bottom: 5% !important;

        @include media-breakpoint-down(md){
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        height: 90vh !important;
    }
    .modal-body{
        overflow: scroll;
        flex-wrap: wrap;
    }

    .error-modal-control{
        display: flex;
        flex-direction: column;
    }

    .error-icon{
        font-size: 48px;
        color: var(--red-color);
        margin-left: auto;
        margin-right: auto;
        margin-top: 48px;
    }

    .error-text{
        font-size: 20px;
        color: var(--text-color);
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
    }

    .error-button-close{
        width: 60%;
        height: 41px;
        background-color: var(--button-primary-color);
        color: white;
        font-family: $fontMedium;
        font-size: 16px;
        margin-top: 48px;
        margin-left: auto;
        margin-right: auto;
        border: none;
    }
}