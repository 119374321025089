@import "../../../App.scss";

.view-resume-control {
    max-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;

    @include media-breakpoint-down(md){
        max-width: none;
        width: 100vw;
    }

 
    .page-container {
        width: 100%; // 793.7007874
        height: fit-content; // 1122.519685
        margin-bottom: 16px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        overflow: scroll;
        box-shadow: var(--shadow-box);

        @include media-breakpoint-down(md){
            position: absolute;
            top: 0;
            left: 0;
            transform-origin: top left;
            width: auto;
            max-width: none;
        }
        @include media-breakpoint-down(sm){
            position: absolute;
            top: 0;
            left: 0;
            transform-origin: top left;
            width: auto;
            max-width: none;
        }
    }
}

.view-resume-iframe {
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}


.view-resume-grid-control {
    padding-left: 3%;
    padding-right: 3%;
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 36px;
    max-width: 100%;

    @include media-breakpoint-down(xl) {
        grid-template-columns: 30% 70%;
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 100%;
    }
    @include media-breakpoint-down(lg) {
        grid-template-columns: 100%;
    }


    .view-resume-divider-text {
        font-size: 14px;
        font-family: $fontRegular;
        color: var(--text-gray-color);
        margin-bottom: 14px;
    }

    .view-resume-header-control {
        display: flex;
        margin-bottom: 14px;

        .view-resume-header-title {
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--primary-color);
            margin-bottom: auto;
            margin-top: auto;
        }

        .view-resume-switch-control {
            margin-left: auto;
            font-family: $fontMedium;

            .view-resume-switch-text {
                font-size: 14px;
                font-family: $fontMedium;
            }
        }
    }

    .view-resume-input-select {
        width: 200px;
        height: 41px;
        font-size: 14px;
    }
    .view-resume-back-button {
        border: 1px solid var(--primary-color);
        color: white;
        font-family: $fontMedium;
        font-size: 14px;
        height: 35px;
        min-width: 100px;
        background-color: var(--button-primary-color);
        &:hover{
            background-color: var(--primary-color);
        }
    }
    .view-resume-edit-button {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        font-family: $fontMedium;
        font-size: 14px;
        height: 35px;
        width: 100px;
        background-color: white;
        margin-left: auto;

        &:hover{
            background-color: var(--primary-color);
        }
    }

    .view-resume-text-grid-control {
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 16px;

        @include media-breakpoint-down(xl) {
            grid-template-columns: 100%;
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: 20% 80%;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }
    

        .view-resume-text-title {
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--primary-color);
            margin-bottom: auto;
        }

        .view-resume-text-desc {
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--primary-color);
            margin-bottom: auto;
        }
    }

}

.view-builder-control {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    box-shadow: var(--shadow-box);
    border: none;
    padding: 16px;
    height: fit-content;

    @include media-breakpoint-down(xl) {
        margin-right: 14px;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 14px;
        overflow: scroll;
        margin-right: 0px;
    }

    .view-builder-download {

        font-size: 14px;
        text-decoration: underline;
        color: white;
        background-color: var(--button-primary-color);
        border: none;
        font-family: $fontMedium;
        cursor: pointer;

        &:hover {
            background-color: var(--button-primary-color);
        }
    }

    .view-builder-downloading-control {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        .view-builder-downloading-progress {
            margin-left: auto;
            margin-right: auto;
        }

        .view-builder-downloading-text {
            font-size: 16px;
            color: var(--text-gray-color);
            font-family: $fontRegular;
            margin-left: auto;
            margin-right: auto;
        }

    }

    .view-builder-text-consent {
        margin-top: 16px;
        font-size: 12px;
        text-align: left;
        color: var(--text-gray-color);
        font-family: $fontRegular;

        a{
            font-size: 12px;
        }
    }

    .view-builder-text-consent-a {
        color: var(--link-color);
        text-decoration: underline;
        cursor: pointer;
        font-family: $fontRegular;
        font-size: 12px !important;
    }

}

.view-builder-text-control {
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 48px;
    display: block;
    width: 100%;

    .view-builder-resume-url-control {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

.view-builder-text {
    font-size: 16px;
    font-family: $fontMedium;
    color: var(--text-color);

}

.view-builder-text-a {
    font-size: 14px;
    font-family: $fontRegular;
    color: #3da8ff;
    text-decoration: underline;
    overflow-wrap: break-word;
    flex-wrap: wrap;
    cursor: pointer;
}