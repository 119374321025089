@import "../../../App.scss";


.resume-parser {
    width: 100%;
    display: flex;
    flex-direction: column;

    

    .resume-parser-control {
        width: 100%;
        display: grid;
        grid-template-columns: 30% 70%;
    }


    .resume-parser-skill-flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .resume-parser-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
    }

    .resume-parser-exp-bold{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
    }

    .resume-parser-exp-desc{
        font-size: 13px;
        font-family: $fontMedium;
        color: var(--text-gray-color);
    }
    .resume-parser-desc{
        font-size: 14px;
        font-family: $fontRegular;
        color: var(--text-color);
    }
    .resume-parser-divider{
        margin-top: 12px;
        margin-bottom: 12px;
        border: 1px solid var(--border-input);
    }

    .resume-parser-skill-tag-control{
        display: flex;
        flex-wrap: wrap;

        .resume-parser-skill-tag{
            border-radius: 24px;
            padding-left: 12px;
            padding-right: 12px;
            height: 30px;
            display: flex;
            background-color: var(--primary-color);
            color: white;
            font-size: 14px;
            margin-right: 8px;
            margin-bottom: 6px;
        }
    }
}