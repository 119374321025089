@import "../../App.scss";


.not-found-main{
    width: 100vw;
    height: 100vh;
    display: flex;


    .not-found-main-control{
        border: none;
        border-radius: 14px;
        box-shadow: var(--shadow-box);
        padding: 16px;
        width: 60%;
        height: 60%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .not-found-main-logo{
            width: auto;
            height: 60px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 48px;
        }

        .not-found-main-text{
            font-size: 18px;
            color: var(--text-color);
            font-family: $fontMedium;
            text-align: center;
            margin-top: 24px;

        }
        .not-found-main-link{
            font-size: 18px;
            color: var(--link-color);
            cursor: pointer;
            font-family: $fontRegular;
            text-align: center;
            margin-top: auto;
            text-decoration: underline;

        }
    }
}