@import "../../../App.scss";


.landing-360-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .landing-360-control{
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0 5%;

        .landing-360-logo{
            width: auto;
            height: 60px;
            object-fit: contain;
        }

        .landing-360-button{
            width: auto;
            height: 45px;
            color: white;
            font-size: $fontMedium;
            border: none;
            padding-left: 24px;
            padding-right: 24px;
            background-color: var(--primary-color);
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
            font-size: 16px;
        }
    }
}