@import "../../App.scss";

.setting-main{
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md){
        padding: 4px 4px;
    }
    
    .Mui-selected{
        color: var(--primary-color) !important;
        font-family: $fontMedium !important;

        &:focus{
            background-color: transparent !important;
        }
    }

    .MuiTab-root{
        color: var(--primary-color) !important;
        font-family: $fontRegular;

        &:focus{
            background-color: transparent !important;
        }
    }
    .setting-tab-custom{
        color: var(--primary-color);
    }
    .setting-header-control {
        display: flex;
        @include media-breakpoint-down(md){
            padding: 8px 16px;
        }

        .setting-header {
            font-size: 25px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-bottom: auto;
        }
    }

    .setting-main-control{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 24px;
        z-index: 1;

        @include media-breakpoint-down(md){
            padding: 4px;
        }
    }

    .setting-content-control{
        width: 100%;
        background-color: white;
        border-radius: 24px;
        z-index: 1;
        margin-top: 24px;
        padding: 24px;

        @include media-breakpoint-down(md){
            padding: 12px;
        }
    }

    .setting-content-inside-control{
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        @include media-breakpoint-down(md){
            justify-content: start;
        }

        .setting-content-select{
            width: 100px;
            height: 41px;
        }

        .setting-divider{
            margin-top: 4px;
            margin-bottom: 4px;
            border-color: var(--border-input);
        }

        .setting-content-inside-item-checkbox{
            display: flex;

            .MuiFormControlLabel-label{
                
                @include media-breakpoint-down(md){
                    text-align: center;
                    font-size: 11px !important;
                }
            }

          

            .setting-content-inside-item-checkbox-title-control{
                display: flex;
                flex-direction: column;
                margin-left: 16px;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(md){
                    margin-left: 12px;
                }
            }
            .setting-content-inside-item-checkbox-title{
                font-size: 16px;
                color: var(--text-color);
                font-family: $fontMedium;

                @include media-breakpoint-down(md){
                    font-size: 12px;
                }
            }

            .setting-content-inside-item-checkbox-desc{
                font-size: 13px;
                color: var(--text-color);
                font-family: $fontRegular;
                @include media-breakpoint-down(md){
                    font-size: 12px;
                }
            }
        }
    }

    .setting-content-button-save{
        width: 350px;
        height: 41px;
        color: white;
        font-family: $fontMedium;
        font-size: 16px;
        border: none;
        margin-top: 24px;
        border-radius: 8px;
        background-color: var(--primary-color);

        &:hover{
            box-shadow: var(--shadow-box);
            background-color: var(--primary-color);
        }

        @include media-breakpoint-down(md){
            font-size: 13px;
            width: 40%;
            height: 36px;
        }
    }
}