@import "../App.scss";


.confirm-profile{
    .modal-dialog {
        max-width: 70% !important;
        width: 70% !important;
        min-height: 60vh !important;
        max-height: 90vh !important;
        margin-bottom: 5% !important;
        display: flex;
        @media (min-width: 1200px) { 

            width: 50% !important;
            max-width: 50% !important;
         }
        @include media-breakpoint-down(md){
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        margin-top: auto;
        margin-bottom: auto;
        min-height: auto !important;
        max-height: 90vh !important;
    }

    .remove-resume-control{
      
        padding: 24px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;


        .remove-resume-header{
            font-size: 18px;
            color: var(--text-color);
            font-family: $fontMedium;
            text-align: center;
        }

        .remove-resume-button-control{
            margin-top: 36px;
            display: grid;
            grid-template-columns: 50% 50%;
        }

        .remove-resume-button-confirm{
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            min-height: 44px;
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--text-white-color);
            border: none;
            background-color: var(--button-primary-color);
        }

        .remove-resume-button-no-update{
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            min-height: 44px;
            font-size: 14px;
            border: none;
            font-family: $fontMedium;
            color: var(--text-white-color);
            background-color: var(--red-color);

            .text-sub{
                font-size: 11px;
            }
        }
    }
}