
@import "../../App.scss";

.checking-auth{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    .checking-auth-loading-control{
        margin: auto;
        display: flex;
        flex-direction: column;

        .checking-auth-loading{
            width: 50px;
            height: 50px;
            margin-left: auto;
            margin-right: auto;
        }

        .checking-auth-loading-text{
            font-size: 18px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-top: 16px;
        }
    }
}