@import "../App.scss";

.redirect-modal{
    display: flex;
    flex-direction: column;
    padding: 36px;

    .redirect-modal-loading{
        margin-top: 36px;
        margin-left: auto;
        margin-right: auto;
        width: 35px;
        height: 35px;
    }

    .redirect-modal-text{
        margin-top: 24px;
        font-size: 14px;
        color: var(--text-color);
        margin-left: auto;
        margin-right: auto;
        font-family: $fontMedium;
    }

    .redirect-modal-logo{
        width: auto;
        height: 35px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
    }

}