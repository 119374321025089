@import "../../App.scss";


.jobs-main {
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;

    @include media-breakpoint-down(md){
        padding: 4px 4px;
    }

    .Mui-selected {
        color: var(--primary-color) !important;
        font-family: $fontMedium !important;
    }

    .MuiTab-root {
        color: var(--primary-color) !important;
        font-family: $fontRegular;
    }
    .jobs-header-find-work-button{
        min-width: 100px;
        height: 41px;
        border-radius: 8px;
        background-color: white;
        border: 1px solid var(--primary-color);
        color: var(--text-color);
        font-size: 14px;
        font-family: $fontMedium;
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 24px;

        &:hover{
            background-color: var(--button-primary-color);
            border: none;
        }
    }
    .jobs-header-control {
        display: flex;
        @include media-breakpoint-down(md){
            padding: 12px 16px;
        }

        .jobs-header-left-control{
            display: flex;
            flex-direction: column;
        }

     
        .jobs-header {
            font-size: 25px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-bottom: auto;
            margin-top: auto;

            @include media-breakpoint-down(md){
                font-size: 20px;
                    margin-top: auto;
                    margin-bottom: auto;
            }
        }

        .jobs-header-looking-for-jobs-control {
            margin-left: auto;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(md){
                margin-top: auto;
                margin-bottom: auto;
            }

            .MuiFormControlLabel-label {
                font-size: 20px !important;

                @include media-breakpoint-down(md){
                    font-size: 14px !important;
                 
                }
            }

            .jobs-header-looking-for-jobs-switch {
                margin-top: 12px;

                @include media-breakpoint-down(md){
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }

    .jobs-company-control{
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .jobs-company-title{
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--primary-color);
        }

        .jobs-company-list-control{
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            margin-top: 36px;

            @include media-breakpoint-down(md){
                grid-template-columns: 50% 50%;
            }

            .jobs-company-list-item{
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;
                cursor: pointer;

                .jobs-company-list-logo{
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-left: auto;
                    margin-right: auto;
                }
                .jobs-company-list-name{
                    font-size: 16px;
                    color: var(--primary-color);
                    font-family: $fontMedium;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    margin-top: 24px;
                }
            }
        }

        .jobs-company-loading-more{
            display: flex;
            margin-top: 24px;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;

            .jobs-company-loading-more-progress{
                width: 30px;
                height: 30px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .jobs-company-loading-more-text{
                margin-left: auto;
                margin-right: auto;
                font-size: 16px;
                font-family: $fontMedium;
                color: var(--primary-color);
            }
        }

        .jobs-company-button-load-more{
            margin-top: 24px;
            background-color: var(--primary-color);
            border-radius: 8px;
            height: 41px;
            border: none;
            color: var(--text-white-color);
            font-size: 17px;
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .jobs-loading-main {
        display: flex;

        height: 100%;
        width: 100%;

        .jobs-loading-control {
            display: flex;
            flex-direction: column;
            margin: auto;

            .jobs-loading-icon {
                margin-left: auto;
                margin-right: auto;
                font-size: 48px;
                color: var(--primary-color);
                animation: bounce 2s infinite;
            }

            .jobs-loading-text {
                font-size: 16px;
                color: var(--text-color);
                font-family: $fontMedium;
                margin-top: 18px;
            }
        }


    }

    .jobs-table-list-control{
        border-radius: 24px;
        background-color: white;
        box-shadow: var(--shadow-box);
        margin-top: 24px;
        display: flex;
        z-index: 1;

        .MuiTableContainer-root{
            background-color: white;
            z-index: 1;
            border-radius: 24px;
        }

        .jobs-table-list-header{
            font-size: 14px;
            font-family: $fontMedium !important;
            color: var(--text-color);
        }
        .jobs-table-list-desc{
            font-size: 14px;
            font-family: $fontRegular;
            color: var(--text-color);
        }
    }

    .jobs-card-list-control {
        width: 100%;
        display: flex;
        margin-top: 24px;
        flex-direction: column;
        @include media-breakpoint-down(md){
            padding: 12px 16px;
        }

        .jobs-card-item {
            width: 100%;
            height: 200px;
            border-radius: 14px;
            border: none;
            display: flex;
            background-color: white;
            flex-direction: column;
            margin-bottom: 14px;
            padding: 24px 14px;

            @include media-breakpoint-down(md){
                height: auto;
            }

            .jobs-card-top-section-control {
                display: flex;
                height: 100%;
                width: 100%;

                @include media-breakpoint-down(md){
                    flex-direction: column;
                    position: relative;
                }

                .jobs-card-content-control{
                    width: 85%;
                    display: flex;
                    flex-direction: column;
                    padding-left: 16px;
                    padding-right: 16px;

                    @include media-breakpoint-down(md){
                        width: 100%;
                    }

                    .jobs-card-share-section-control{
                        margin-left: auto;
                        display: flex;

                        @include media-breakpoint-down(md){
                            position: absolute;
                            top: 0;
                            right: 0;
                        }

                        .jobs-card-share-icon{
                            cursor: pointer;
                            font-size: 22px;
                            color: var(--primary-color);
                            margin-right: 18px;
                        }
                    }

                    .jobs-card-job-detail-control{
                        display: grid;
                        grid-template-columns: 70% 30%;

                        @include media-breakpoint-down(md){
                            grid-template-columns: 100%;
                        }
                        
                        .jobs-card-job-detail-right-control{
                            display: flex;
                            flex-direction: column;
                            margin-left: auto;
                            @include media-breakpoint-down(md){
                                margin-right: auto;
                            }

                            .jobs-card-job-detail-right-item-control{
                                margin-top: auto;
                                margin-bottom: auto;
                                display: flex;
                                flex-direction: column;
                            }

                            .jobs-card-job-detail-salary{
                                margin-top: 24px;
                                font-size: 18px;
                                font-family: $fontMedium;
                                color: var(--primary-color);
                                text-align: right;

                                @include media-breakpoint-down(md){
                                    text-align: center;
                                }
                            }

                            .jobs-card-job-detail-apply-job{
                                border-radius: 8px;
                                border: none;
                                background-color: var(--primary-color);
                                font-family: $fontMedium;
                                color: var(--text-white-color);
                                font-size: 18px;
                                margin-top: 12px;
                                width: 200px;
                                margin-left: auto;
                                @include media-breakpoint-down(md){
                                    margin-right: auto;
                                }
                            }

                            .jobs-card-job-detail-view-job{
                                cursor: pointer;
                                text-decoration: underline;
                                font-size: 13px;
                                color: var(--primary-color);
                                font-family: $fontMedium;
                                margin-top: 6px;
                                margin-left: auto;
                                @include media-breakpoint-down(md){
                                    margin-left: auto;
                                    margin-right: auto;
                                }

                            }
                        }
                        .jobs-card-job-detail-left-control{
                            display: flex;
                            flex-direction: column;
                            margin-bottom: auto;

                            .jobs-card-job-title{
                                font-size: 20px;
                                font-family: $fontMedium;
                                color: var(--primary-color);

                                @include media-breakpoint-down(md){
                                    margin-left: auto;
                                    margin-right: auto;
                                    margin-top: 24px;
                                }
                            }

                            .jobs-card-criteria-control{
                                display: flex;
                                margin-top: 12px;
                                @include media-breakpoint-down(md){
                                    flex-direction: column;
                                }

                                .jobs-card-criteria-item{
                                    display: flex;
                                    margin-right: 14px;

                                    @include media-breakpoint-down(md){
                                        margin-top: 14px;
                                        margin-left: auto;
                                        margin-right: auto;
                                    }

                                    .jobs-card-criteria-item-icon{
                                        font-size: 20px;
                                        color: var(--primary-color);

                                        @include media-breakpoint-down(md){
                                            width: 20px;
                                        }
                                    }
                                    .jobs-card-criteria-item-title{
                                        font-size: 13px;
                                        font-family: $fontRegular;
                                        color: var(--primary-color);
                                        margin-left: 6px;
                                        @include media-breakpoint-down(md){
                                            margin-left: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .jobs-card-company-control {
                    width: 15%;
                    height: 100%;
                    display: flex;

                    @include media-breakpoint-down(md){
                        flex-direction: column;  
                        width: 100%; 
                        height: auto;        
                    }

                    .jobs-card-company-default {

                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        border: 2px solid var(--primary-color);
                        margin: auto;
                        display: flex;

                        .jobs-card-company-default-icon{
                            font-size: 48px;
                            margin: auto;
                            color: var(--primary-color);
                        }

                        .jobs-card-company-default-img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border-radius: 50%;
                        }
                    }
                }


            }



        }

        .jobs-card-item-active {
            box-shadow: var(--shadow-box);
        }
    }

    @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-30px);
        }

        60% {
            transform: translateY(-15px);
        }
    }

    .not-apply-job-control{
        display: flex;
        height: 50vh;
        width: 100%;
        background-color: white;
        border-radius: 24px;
        z-index: 1;

        .not-apply-job-flex{
            margin: auto;
            display: flex;
            flex-direction: column;
            z-index: 1;

            .not-apply-job-img{
                width: 50px;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                color: var(--primary-color);
                margin-right: auto;
            }

            .not-apply-job-text{
                margin-top: 24px;
                font-size: 14px;
                color: var(--text-color);
                font-weight: $fontRegular;
            }
        }
    }
}