@import "../../App.scss";


.resume-checker {
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;

    .resume-checker-upload-new{
        margin-top: 14px;
        background-color: var(--primary-color);
        display: flex;
        font-size: 15px;
        width: fit-content;
        color: white;
        min-height: 41px;
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 16px;

        .resume-checker-upload-new-title{
            margin-top: auto;
            margin-bottom: auto;
            text-align: left;
            font-family: $fontMedium;
        }
        .resume-checker-upload-new-name{
            margin-top: auto;
            margin-bottom: auto;
            text-align: left;
            margin-left: 8px;
            font-family: $fontRegular;
        }

        .resume-checker-upload-new-abort{
            cursor: pointer;
            width: 18px;
            height: 18px;
            margin-top: auto;
            margin-left: 24px;
            margin-bottom: auto;
        }
    }
    
    .resume-checker-ai-loading{
        width: 70px;
        height: 70px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .resume-checker-control {
        display: flex;
        flex-direction: column;

        .resume-checker-header-control {
            display: flex;

            .resume-checker-header {
                font-size: 25px;
                color: var(--text-color);
                font-family: $fontMedium;
                margin-bottom: auto;
            }
        }

        .resume-checker-form-control {
            width: 90%;
            min-height: 80vh;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
            border-radius: 24px;
            z-index: 1;
            padding: 24px;
            background-color: white;
            box-shadow: var(--shadow-box);

            @include media-breakpoint-down(md) {
                width: 100%;
            }


            .MuiStepLabel-label {
                font-size: 16px;
                color: var(--text-color);
                font-family: $fontMedium !important;
            }


            .resume-checker-form-input-header {
                font-size: 18px;
                color: var(--text-color);
                font-family: $fontMedium !important;
            }

            .resume-checker-form-grid {
                display: grid;
                grid-template-columns: 50% 50%;
                margin-top: 36px;
                height: auto;

                @include media-breakpoint-down(lg) {
                    grid-template-columns: 100%;
                }
            }

            .resume-checker-form-input-control {
                margin-right: auto;
                margin-top: 36px;
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .resume-checker-form-left {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    margin-bottom: 36px;
                }
            }

            .resume-checker-form-right {
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(lg) {
                    margin-top: 36px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0px;
                }
            }

            .resume-checker-upload-resume-new-control {
                margin-top: 6px;
                font-size: 13px;
                font-family: $fontRegular;
                display: flex;

                .resume-checker-upload-resume-new-link {
                    color: var(--link-color);
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: 6px;
                }
            }

            .resume-checker-form-input {
                margin-right: auto;
                margin-top: 16px;
                width: 70%;
                font-size: 14px;
                font-family: $fontRegular !important;
                color: var(--text-color);

                @include media-breakpoint-down(md) {
                    width: 100%;
                }



                .resume-checker-form-input-item-control {
                    display: flex;
                    flex-direction: column;
                    margin-top: 14px;
                }

                .resume-checker-form-input-title {
                    font-size: 14px;
                    color: var(--text-color);
                    font-family: $fontMedium;
                }

                .resume-checker-form-input-error {
                    font-size: 12px;
                    color: var(--red-color);
                    font-family: $fontRegular;
                }
            }
        }

        .resume-checker-form-submit-button {
            width: 250px;
            height: 41px;
            background-color: var(--button-primary-color);
            font-family: $fontMedium;
            font-size: 14px;
            border-radius: 8px;
            border: none;
            margin-top: 16px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .resume-checker-form-submit-button-disabled {
            width: 250px;
            height: 41px;
            background-color: var(--disabled-color);
            font-family: $fontMedium;
            font-size: 14px;
            border-radius: 8px;
            border: none;
            margin-top: 16px;
        }
    }

    .resume-checker-form-bold-extra {
        font-size: 20px;
        font-family: $fontMedium;
        margin-left: 4px;
        color: var(--primary-color);
        margin-right: 4px;
    }

    .resume-checker-form-bold-extra-green {
        font-size: 20px;
        font-family: $fontMedium;
        color: green;
        margin-right: 4px;
    }

    .resume-checker-form-bold-extra-red {
        font-size: 20px;
        font-family: $fontMedium;
        color: var(--soft-red);
        margin-right: 4px;
    }

    .resume-checker-form-right-detail-control {
        width: 90%;
        margin-left: auto;
        border-radius: 8px;
        margin-right: auto;
        border: 1px solid var(--border-input);
        padding: 16px;
        height: fit-content;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin-top: 36px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .resume-checker-form-right-wait-input-control {
            height: 400px;
            display: flex;
            flex-direction: column;

            .resume-checker-form-right-wait-input-item {
                display: flex;
                flex-direction: column;
                margin: auto;

                .resume-checker-form-right-wait-input-icon {
                    font-size: 36px;
                    color: var(--primary-color);
                    margin-left: auto;
                    margin-right: auto;
                }

                .resume-checker-form-right-wait-input-text {
                    font-size: 18px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                    margin-top: 16px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .resume-checker-form-right-header {
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-bottom: 16px;
        }

        .resume-checker-form-right-content-item {
            display: flex;
            flex-direction: column;
            margin-top: 14px;
        }

        .resume-checker-form-right-title {
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--text-color);
        }

        .resume-checker-form-right-description {
            font-size: 13px;
            font-family: $fontRegular;
            color: var(--text-color);
        }
    }

    .resume-checker-reset-button {
        margin-top: 16px;
        background-color: var(--button-primary-color);
        height: 39px;
        width: 300px;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 8px;
        font-size: 14px;
        color: white;
        border: none;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .resume-checker-result-control {
        display: grid;
        grid-template-columns: 40% 60%;
        margin-top: 36px;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 100%;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }

        .resume-checker-result-item-control {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid var(--border-input);
            padding: 16px;
            border-radius: 8px;

            @include media-breakpoint-down(lg) {
                margin-top: 36px;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .resume-checker-result-match-control {
            margin-left: auto;
            margin-right: auto;
            margin-top: 16px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;

            .resume-checker-result-match-text {
                font-size: 18px;
                color: var(--text-color);
                font-family: $fontRegular;
            }

            .resume-checker-result-match-result {
                font-size: 60px;
                color: var(--text-blue-color);
                font-family: $fontMedium;
            }
        }

        .resume-checker-result-card-control {
            background-color: white;
            border: 1px solid var(--primary-color);
            border-radius: 8px;
            padding: 16px;
            margin-top: 16px;
            color: var(--primary-color);
            display: flex;
            flex-direction: column;

            .resume-checker-result-card-header {
                font-size: 18px;
                font-family: $fontMedium;
                margin-bottom: 4px;
            }

            .resume-checker-result-card-sub-header {
                font-size: 14px;
                font-family: $fontLight;
                margin-bottom: 4px;
            }

            .resume-checker-result-card-grid-item {
                display: grid;
                grid-template-columns: 20% 80%;
                border-top: 1px solid var(--border-input);
                padding-top: 14px;
                padding-bottom: 14px;
            }

            .resume-checker-result-item-value-control {
                display: flex;
                flex-direction: column;

                .resume-checker-result-item-value-suggest-text {
                    font-size: 13px;
                    font-family: $fontRegular;
                    color: var(--text-color);
                }

                .resume-checker-result-item-value-header {
                    font-size: 14px;
                    color: var(--text-gray-color);
                    font-family: $fontRegular;
                }

                .resume-checker-result-item-value-check-control {
                    display: flex;
                    margin-top: 16px;
                    margin-bottom: 16px;
                }

                .resume-checker-result-item-value-check-document-control {
                    display: flex;
                    margin-bottom: 16px;
                }

                .resume-checker-result-item-value-bold {
                    font-size: 16px;
                    color: var(--text-blue-color);
                    font-family: $fontMedium;
                }

                .resume-checker-result-item-value-icon {
                    font-size: 16px;
                    color: green;
                    margin-right: 16px;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .resume-checker-result-item-value-icon-red {
                    font-size: 16px;
                    color: red;
                    margin-right: 16px;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .resume-checker-result-item-value-desc {
                    font-size: 13px;
                    color: var(--text-color);
                    font-family: $fontRegular;
                }
            }

            .resume-checker-result-tag-active-skill-control {
                display: flex;
                flex-wrap: wrap;
                margin-top: auto;
                margin-bottom: auto;
            }

            .resume-checker-result-tag-active-skill {
                border-radius: 24px;
                padding-left: 14px;
                padding-right: 14px;
                margin-right: 6px;
                display: flex;
                justify-content: center;
                background-color: var(--primary-color);
                color: white;
                font-size: 13px;
                margin-bottom: 6px;
                height: 30px;
            }

            .resume-checker-result-tag-red-skill {
                border-radius: 24px;
                padding-left: 14px;
                padding-right: 14px;
                margin-right: 6px;
                display: flex;
                justify-content: center;
                background-color: var(--soft-red);
                color: white;
                font-size: 13px;
                margin-bottom: 6px;
                height: 30px;
            }

            .resume-checker-result-tag-other-skill {
                border-radius: 24px;
                padding-left: 14px;
                padding-right: 14px;
                margin-right: 6px;
                display: flex;
                justify-content: center;
                background-color: var(--disabled-color);
                color: white;
                margin-bottom: 6px;
                font-size: 13px;
                height: 30px;
            }

            .resume-checker-result-card-grid-item-wide {
                display: grid;
                grid-template-columns: 30% 70%;
                border-top: 1px solid var(--border-input);
                padding-top: 14px;
                padding-bottom: 14px;


            }

            .resume-checker-result-card-title {
                font-size: 16px;
                font-family: $fontMedium;
            }

            .resume-checker-result-card-desc {
                font-size: 16px;
                font-weight: 300;
                font-family: $fontLight;
            }
        }

        .resume-checker-result-top-section-control {
            display: flex;
            margin-top: 24px;

            .resume-checker-result-top-text {
                font-size: 16px;
                font-family: $fontRegular;
                color: var(--text-gray-color);
            }

            .resume-checker-result-top-share {
                display: flex;
                margin-left: auto;
                margin-right: 16px;

                .resume-checker-result-top-icon {
                    font-size: 24px;
                    color: var(--text-blue-color);
                    margin-right: 12px;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .resume-checker-reesult-top-text {
                    font-size: 16px;
                    color: var(--text-blue-color);
                    font-family: $fontMedium;
                }
            }
        }
    }
}