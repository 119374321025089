@import "../../App.scss";


.personality-test-main {
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;


    .personality-test-main-control {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .personality-test-main-header-control {
        display: flex;
        width: 100%;

        .personality-test-main-header {
            font-size: 25px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .personality-test-main-loading-control{
        min-height: 80vh;
        width: 100%;
        display: flex;

        .personality-test-main-loading{
            margin: auto;
            display: flex;
            flex-direction: column;

            .personality-test-main-loading-progress{
                width: 25px;
                height: 25px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }
            .personality-test-main-loading-text{
                font-size: 16px;
                color: var(--text-color);
                font-family: $fontRegular;
                margin-left: auto;
                margin-right: auto;
                margin-top: 16px;
            }
        }
    }

}

.iframe-personality{
    width: auto;
    height: 100vh;
    display: flex;
    margin-top: 6px;
    flex-direction: column;
    border-radius: 8px;
}