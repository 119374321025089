@import "../../../../App.scss";

.MuiAutocomplete-option,
.MuiAutocomplete-listbox,
.MuiAutocomplete-groupLabel {
    font-size: 14px !important;
    font-family: $fontMedium !important;
}

.job-opportunity-main {


    .job-opportunity-item-control{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 14px;
    }

    .job-opportunity-item-red-error{
        font-size: 11px;
        margin-top: 3px;
        color: red;
        font-family: $fontRegular;
    }

    .job-opportunity-item{
        margin-bottom: 14px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .job-opportunity-item-divider{
        font-size: 13px;
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .job-opportunity-title {
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-bottom: 14px;
    }

}