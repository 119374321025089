@import "../../App.scss";


.form-input-main {

    .MuiSvgIcon-root{
        color: var(--primary-color);
    }
    .MuiTextField-root{
        width: 100%;
    }

    .MuiOutlinedInput-root{
        height: 41px !important;
        color: var(--primary-color);
    }
    .MuiOutlinedInput-root > input{
        color: var(--primary-color);
    }
    .form-input-control {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
        width: 100%;
        color: var(--primary-color);

        .form-title-text {
            font-size: 14px;
            color: var(--primary-color);
            font-family: $fontRegular;
            margin-bottom: 4px;
        }
        .form-input-custom-textarea {
            border-radius: 6px;
            border: 1px solid var(--border-input);
            min-height: 41px;
            width: 100%;
            font-size: 14px;
        }
        .form-input-custom {
            border-radius: 6px;
            border: 1px solid var(--border-input);
            height: 41px;
            width: 90%;
            color: var(--primary-color);
            font-size: 14px;
            @include media-breakpoint-down(md){
                width: 100%;
            }

            &::placeholder{
                color: var(--border-input);
            }
        }

        .form-input-custom-error {
            border-radius: 6px;
            border: 2px solid var(--red-color);
            height: 41px;
            width: 90%;
            font-size: 14px;
        }

        .form-error {
            margin-top: 4px;
            font-size: 13px;
            color: var(--red-color);
            font-family: $fontRegular;
        }
    }

    .form-input-button {
        margin-top: auto;
        width: max-content;
        height: 41px;
        min-width: 180px;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 8px;
        background-color: var(--button-primary-color);
        color: white;
        font-size: 17px;
        margin-left: auto;
        margin-right: auto;
        font-family: $fontMedium;
        border: none;

        &:hover{
            background-color: var(--button-primary-color);
            box-shadow: var(--shadow-box);
        }

        &:active {
            background-color: var(--button-primary-color) !important;
        }

        @include media-breakpoint-down(md){
            width: 100%;
            margin-bottom: 4px;
            order: 0;
            margin-top: 8px;
        }
    }

    .form-input-button-back{
        margin-top: auto;
        width: max-content;
        padding-left: 24px;
        padding-right: 24px;
        min-width: 180px;
        height: 41px;
        border-radius: 8px;
        border: 1px solid var(--button-primary-color);
        color: var(--button-primary-color);
        font-size: 17px;
        margin-left: auto;
        margin-right: auto;
        font-family: $fontMedium;
        background-color: transparent;

        &:hover{
            background-color: var(--button-primary-color);
            box-shadow: var(--shadow-box);
        }

        &:active {
            background-color: var(--button-primary-color) !important;
        }

        @include media-breakpoint-down(md){
            width: 100%;
            margin-bottom: 4px;
            order: 1;
            margin-top: 8px;
        }
    }
}