@import "../../../App.scss";

.step-1-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  

    .MuiStack-root{
        padding-top: 1px !important;
        margin-bottom: 0px;
    }
    .MuiOutlinedInput-notchedOutline{
        border-width: 0px !important;
        border-color: var(--border-input) !important;
    }
        
        .step-1-main-form-control{
            background-color: white;
            min-height: 450px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 24px;
            padding: 24px 5%;
            box-shadow: 0px 0px 18px -5px var(--primary-color);
            position: relative;

            .step-1-form-title{
                font-size: 20px;
                color: var(--primary-color);
                font-family: $fontMedium;
            }

            .step-1-form-grid{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-top: 24px;
                column-gap: 18px;
                width: 100%;

                @include media-breakpoint-down(md){
                    grid-template-columns: 100%;
                }
            }
        }
        
    
}