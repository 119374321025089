@import "../App.scss";


.edit-profile{
    .modal-dialog {
        max-width: 70% !important;
        width: 70% !important;
        min-height: auto !important;
        max-height: 90vh !important;
      
        margin-bottom: 5% !important;
        @media (min-width: 1200px) { 

            width: 50% !important;
            max-width: 50% !important;
         }
        @include media-breakpoint-down(md){
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        min-height: auto !important;
        max-height: 90vh !important;
        border-radius: 24px !important;
        border: none;
        box-shadow: var(--shadow-box);
    }

    .edit-profile-content{
        height: 100%;
        overflow-y: auto;
    }
}