@import "../../../../App.scss";

.certificates-control{
    display: flex;
    flex-direction: column;
    margin-top: 12px;

  

    .certificates-title-control{
        display: flex;
    }

    .certificates-title{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-color);
        margin-top: auto;
        margin-bottom: auto;
      
    }

    .certificate-no-control{
        display: flex;
        height: 30vh;
        width: 100%;

        .certificate-no-main{
            display: flex;
            flex-direction: column;
            margin: auto;

            .certificate-no-img{
                width: 90px;
                height: 90px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .certificate-no-text{
                margin-top: 16px;
                font-size: 14px;
                margin-left: auto;
                margin-right: auto;
                color: var(--primary-color);
                font-family: $fontRegular;
            }
        }
    }

    .certificates-form-control{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 36px;
        .certificates-form-group-control{
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            border-bottom: 1px solid var(--border-input);

            .certificates-form-group-title{
                font-size: 14px;
                color: var(--text-color);
                font-family: $fontMedium;
            }

            .certificates-form-input{
                height: 41px;
                width: 100%;
                font-size: 14px;
                font-family: $fontRegular;
                border-radius: 8px;
                border: 1px solid var(--border-input);
            }

            
            .certificates-form-input-textarea{
                height: 41px;
                width: 100%;
                font-size: 14px;
                font-family: $fontRegular;
                border-radius: 8px;
                border: 1px solid var(--border-input);
                padding: 12px 16px;
            }
        }

        .certificates-form-item-control{
            display: grid;
            grid-template-columns: 100%;
            padding-bottom: 24px;
        }

        .certificates-form-button-remove{
            border: none;
            height: 41px;
            width: auto;
            background-color: var(--red-color);
            font-size: 14px;
            color: var(--text-white-color);
            font-family: $fontMedium;
            border-radius: 8px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
        }
        
    }
.certificates-form-input-title{
    font-size: 14px;
    font-family: $fontMedium;
    color: var(--text-color);
    margin-top: 8px;
}
    .certificates-submit-button{
        border: none;
        height: 41px;
        min-width: 100px;
        background-color: var(--primary-color);
        font-size: 14px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;
        width: min-content;
    }
    .certificates-add-button{
        margin-left: auto;
        border: none;
        min-width: 100px;
        height: 41px;
        background-color: var(--primary-color);
        font-size: 13px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 24px;
        padding-right: 24px;

    }
   
}