@import "../../App.scss";


.interview-main {
    width: 100vw;
    height: 100%;
    background-color: var(--bg-color);
    padding: 36px 36px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md){
        padding: 12px 12px;
    }


    .interview-main-control {
        display: flex;
        flex-direction: column;
        width: 100%;

        .interview-main-header-control {
            display: flex;
            width: 100%;

            .interview-main-header {
                font-size: 25px;
                color: var(--text-color);
                font-family: $fontMedium;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(md){
                    font-size: 20px;
                }
            }

            .interview-main-add-button {
                width: auto;
                height: 46px;
                margin-left: 16px;
                background-color: white;
                border: 2px solid var(--primary-color);
                border-radius: 8px;
                color: var(--primary-color);
                font-family: $fontMedium;
                font-size: 16px;

                &:hover {
                    background-color: var(--primary-color);
                    color: white;
                }

                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
        }



        .interview-form-control {
            width: 90%;
            min-height: 80vh;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
            border-radius: 24px;
            z-index: 1;
            padding: 24px;
            background-color: white;
            box-shadow: var(--shadow-box);


            .MuiStepLabel-label {
                font-size: 16px;
                color: var(--text-color);
                font-family: $fontMedium !important;

                @include media-breakpoint-down(md){
                    font-size: 13px;
                }
            }




            .interview-form-input-header {
                font-size: 18px;
                color: var(--text-color);
                font-family: $fontMedium !important;
            }

            .interview-form-grid {
                display: grid;
                grid-template-columns: 50% 50%;
                margin-top: 36px;
                height: auto;

                @include media-breakpoint-down(lg) {
                    grid-template-columns: 100%;
                }
            }

            .interview-form-input-control {
                margin-right: auto;
                margin-top: 36px;
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .interview-form-left {
                display: flex;
                flex-direction: column;
            }

            .interview-form-right {
                display: flex;
                flex-direction: column;

             

            }

            .interview-form-input {
                margin-right: auto;
                margin-top: 16px;
                width: 40%;
                font-size: 14px;
                font-family: $fontRegular !important;
                color: var(--text-color);

                @include media-breakpoint-down(md){
                    width: 100%;
                }

                .interview-form-input-item-control {
                    display: flex;
                    flex-direction: column;
                    margin-top: 14px;
                }

                .interview-form-input-title {
                    font-size: 14px;
                    color: var(--text-color);
                    font-family: $fontMedium;
                }

                .interview-form-input-error {
                    font-size: 12px;
                    color: var(--red-color);
                    font-family: $fontRegular;
                }
            }


        }

    }

    .interview-input-resume-no-resume-control {
        display: flex;
        flex-direction: column;
        margin-top: 14px;
        width: 40%;

        .interview-input-resume-no-resume-or-text{
            font-size: 14px;
            color: var(--text-gray-color);
            font-family: $fontRegular;
            margin-top: 12px;
            margin-bottom: 16px;
        }
        .interview-input-resume-no-resume-text {
            font-size: 14px;
            font-family: $fontRegular;
            color: var(--text-color);
            text-align: center;
        }

        .interview-input-resume-upload-new-button {
            width: 200px;
            height: 41px;
            background-color: var(--text-white-color);
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            border-radius: 8px;
            font-size: 14px;
            margin-left: auto;
            margin-right: auto;
            font-family: $fontMedium;

            &:hover {
                background-color: var(--primary-color);
                color: var(--text-white-color);
            }
        }
    }

    .interview-input-confirm-title {
        font-size: 16px;
        color: var(--text-color);
        font-family: $fontMedium;
    }

    .interview-input-confirm-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .interview-input-confirm-main {
            margin-top: 16px;
            display: grid;
            grid-template-columns: 40% 60%;
            width: 100%;

            .interview-input-confirm-main-title {
                font-size: 16px;
                font-family: $fontMedium;
                color: var(--text-color);

                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }

            .interview-input-confirm-main-desc {
                font-size: 16px;
                font-family: $fontRegular;
                color: var(--text-color);

                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
        }


       

        .interview-input-confirm-button {
            width: 250px;
            height: 41px;
            background-color: var(--primary-color);
            color: var(--text-white-color);
            border: none;
            border-radius: 8px;
            font-family: $fontMedium;
            margin-top: 24px;

            &:hover {
                background-color: var(--primary-color);
            }

            @include media-breakpoint-down(md){
                margin-top: 24px;
                width: 100%;
            }
        }
    }

    .interview-history-button{

        background-color: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        border-radius: 8px;
        font-size: 14px;
        font-family: $fontMedium;
        margin-left: 14px;

        @include media-breakpoint-down(md){
            font-size: 13px;
        }

        &:hover{
            background-color: var(--primary-color);
            color: var(--text-white-color);
            border:none;
        }
    }
    .interview-divider{
        display: none;

        @include media-breakpoint-down(lg){
            display: flex;
            margin-top: 24px;
            margin-bottom: 24px;
            border-width: 2px;
        }
    }
}