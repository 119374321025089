@import "../../../App.scss";


.choose-template{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 60px;


    .choose-template-text{
        font-size: 24px;
        text-align: center;
        font-family: $fontMedium;
        color: var(--primary-color);
        margin-top: 24px;
    }

    .choose-template-control{
       
        margin: auto;
        display: flex;
        width: 100%;
        flex-direction: column;
  

        @include media-breakpoint-down(md){
            width: 100%;
            margin-top: -5%;
        }
        .choose-template-slide-control{
            width: 500px;
            display: flex;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md){
                width: 300px;
            }
            .choose-template-slide{
                width: 400px;
                height: 606px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-down(md){
                    width: 300px;
                    height: 506px;
                 
                }
            }

            .choose-template-slide-box-shadow{
                border: 5px solid var(--primary-color);
                box-shadow: 0px 0px 10px var(--primary-color);
            }
        }

       
    }

    .choose-template-arrow-control{
        width: 90%;
        display: flex;
    }
    .choose-template-arrow{
        width: 50px;
        height: 50px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(md){
            width: 30px;
            height: 30px;
        }
    }

    .choose-template-name-control{
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;

        .choose-template-name-title{
            font-size: 17px;
            font-family: $fontMedium;
            color: var(--primary-color);
            margin-bottom: 12px;
        }

        .choose-template-name-error{
            font-size: 12px;
            font-family: $fontMedium;
            color: var(--red-color);
        }
    }

   
   
    .choose-template-button{
        width: 300px;
        height: 51px;
        margin-top: 36px;
        background-color: var(--button-primary-color);
        border-radius: 8px;
        border: none;
        font-family: $fontMedium;
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 8px 18px 0px rgba(61, 70, 85, 0.30);
    }
}