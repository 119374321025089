@import "../../../App.scss";



.guest-require-login {

    width: 100%;
    height: 100vh;
    display: flex;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(lg){
        background-position: center left;
        background-size: cover;
    }

    @include media-breakpoint-down(sm){
        background-position: center left;
        background-size: cover;
    }

    .guest-require-login-border-control {
        border-radius: 24px;
        background: #FFF;
        padding: 32px;
        min-width: 50%;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        height: fit-content;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 15vh;
        box-shadow: var(--shadow-box);

        @include media-breakpoint-down(lg){
            min-width: 70%;
        }
        @include media-breakpoint-down(md){
            min-width: 80%;
        }
        @include media-breakpoint-down(sm){
            min-width: 50%;
            max-width: 85%;
        }
        .guest-require-login-img-ai-1{
            position: absolute;
            width: 100px;
            height: 100px;
            object-fit: contain;
            right: 0;
            margin-left: auto;
            bottom: 0;
            margin-top: auto;
            margin-right: -40px;
            margin-bottom: 50px;

            @include media-breakpoint-down(sm){
                width: 60px;
                height: 60px;
                margin-right: -20px;
            }
        }
        .guest-require-login-img-ai-2{
            position: absolute;
            width: 60px;
            height: 60px;
            object-fit: contain;
            right: 0;
            margin-left: auto;
            bottom: 0;
            margin-top: auto;
            margin-right: -20px;
            margin-bottom: 190px;

            
            @include media-breakpoint-down(sm){
                width: 30px;
                height: 30px;
                margin-right: -5px;
            }
        }

        .guest-require-login-img-ai-3{
            position: absolute;
            width: 60px;
            height: 60px;
            object-fit: contain;
            left: 0;
            margin-right: auto;
            top: 0;
            margin-bottom: auto;
            margin-left: 20px;
            margin-top: 120px;
            @include media-breakpoint-down(xl){
                margin-left: 5px;
            }
            @include media-breakpoint-down(lg){
                display: none;
            }
        }

        .guest-require-login-img-ai-4{
            position: absolute;
            width: 60px;
            height: 60px;
            object-fit: contain;
            left: 0;
            margin-right: auto;
            bottom: 0;
            margin-top: auto;
            margin-left: -20px;
            margin-bottom: 40px;
        }
        .guest-require-login-img-resume{
            position: absolute;
            width: 150px;
            height: 150px;
            object-fit: contain;
            left: 0;
            margin-left: auto;
            bottom: 0;
            margin-top: auto;
            margin-left: -60px;
            margin-bottom: 40px;

            @include media-breakpoint-down(sm){
                width: 80px;
                height: 80px;
                margin-left: -25px;
            }
        }
        .guest-require-login-headtext-control{
          height: auto;
          width: 350px;
          object-fit: contain;
          margin-left: auto;
          margin-right: auto;
          @include media-breakpoint-down(sm){
            width: 90%;
        }
          
        }
      
        .guest-require-login-detail-control{
            width: 350px;
            height: auto;
            margin-top: 24px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(sm){
                width: 100%;
            }
        }
        .guest-require-login-detail-control-2{
            width: 400px;
            height: auto;
            margin-top: 24px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-down(lg){
                width: 350px;
            }
            @include media-breakpoint-down(md){
                width: 350px;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
            }
        }

        .guest-require-login-button-register{
            width: 50%;
            height: 41px;
            background-color: var(--primary-color);
            border: none;
            color: white;
            font-size: 16px;
            font-family: $fontMedium;
            margin-top: 10vh;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(sm){
                font-size: 14px;
                width: 70%;
                margin-top: 5vh;
            }
        }

        .guest-require-login-button-login{
            width: 50%;
            height: 41px;
            background-color: white;
            border: 1px solid var(--primary-color);
            color: var(--text-color);
            font-size: 16px;
            font-family: $fontMedium;
            margin-top: 12px;
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-down(sm){
                font-size: 14px;
                width: 70%;
            }
        }
    }

}