@import "../App.scss";

.save-modal{
    .save-modal-control{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md){
            width: 70%;
        }
        .save-modal-name{
            font-family: $fontMedium;
            color: var(--text-color);
            font-size: 18px;
        }
    
        .save-modal-input{
            width: 100%;
            margin-top: 16px;
        }

        .save-modal-button{
            width: auto;
            height: 46px;
            margin-top: 16px;
        }

        .save-modal-error{
            margin-top: 12px;
            font-size: 11px;
            color: red;
            font-family: $fontRegular;
        }
    }

    .loading-progress-control{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .loading-progress{
            width: 90px;
            height: 90px;
            margin-left: auto;
            margin-right: auto;
        }

        .loading-progress-text{
            margin-left: auto;
            margin-right: auto;
            margin-top: 16px;
            font-size: 13px;
            color: var(--text-color);
            font-family: $fontRegular;
        }
    }


}