@import "../../../App.scss";


.personality-main{
    display: flex;
    flex-direction: column;


    .personality-loading-control{
        display: flex;
        margin: auto;
        .personality-loading-main{
            margin: auto;
            display: flex;
            flex-direction: column;
        }

        .personality-loading-progress{
            width: 15px;
            height: 15px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .personality-loading-text{
            font-size: 14px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
        }
    }
    .personality-type-text{
        margin-top: 16px;
        font-size: 16px;
        color: var(--primary-color);
        font-family: $fontMedium;
        margin-left: auto;
        margin-right: auto;
    }

    .personality-graph-control{
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    .personality-divider-with-text{
        margin-top: 14px;
        margin-bottom: 14px;
        font-size: 12px;
        color: var(--text-gray-color);
        font-family: $fontRegular;
    }

    .personality-detail-control{
        display: grid;
        grid-template-columns: 80% 20%;


        .personality-detail-title{
            font-size: 14px;
            color: var(--primary-color);
            font-family: $fontMedium;
        }

        .personality-detail-desc{
            font-size: 14px;
            color: var(--primary-color);
            font-family: $fontRegular;
        }
    }

    .personality-add-control{
        display: flex;
        min-height: 150px;

        .personality-add-main-control{
            display: flex;
            margin: auto;
            flex-direction: column;
        }

        .personality-add-main-title{
            font-size: 14px;
            font-family: $fontRegular;
            color: var(--text-color);
            margin-left: auto;
            margin-right: auto;
        }

        .personality-add-button{
            width: 200px;
            height: 41px;
            color: var(--text-white-color);
            background-color: var(--primary-color);
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
            border:none;

            &:hover{
                background-color: var(--primary-color);
            }
        }
    }
    .personality-test-again-button{
        width: 200px;
        height: 41px;
        color: var(--text-white-color);
        background-color: var(--primary-color);
        margin-left: auto;
        margin-right: auto;
        margin-top: 14px;
        border:none;

        &:hover{
            background-color: var(--primary-color);
        }
    }
    .personality-trait-control{
        display: grid;
        grid-template-columns: 33% 33% 33%;

        .personality-trait-item-control{
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;

            .personality-trait-item-img{
                width: 50px;
                height: 50px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }
            .personality-trait-item-text{
                font-size: 13px;
                margin-left: auto;
                margin-right: auto;
                color: var(--primary-color);
                font-family: $fontMedium;
            }
        }
    }

}