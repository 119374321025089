@import "../../../App.scss";

.my-info-view-only{
    width: 80vw;
}

.my-info {
    display: grid;
    grid-template-columns: 70% 30%;
    margin-top: 16px;
    z-index: 1;

  

    @include media-breakpoint-down(lg){
        grid-template-columns: 100%;
    }

    .my-info-about-control {
       
        width: 95%;
        min-height: 400px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(lg){
            width: 100%;
        }

    }

    .my-info-view-resume-button{
        width: 100%;
        margin-top: 16px;
        height: 40px;
        background-color: var(--button-primary-color);
        font-size: 14px;
        color: white;
        border: none;
    }
    .my-info-text-url-edit-button {
        border: 1px solid var(--button-primary-color);
        border-radius: 8px;
        font-family: $fontMedium;
        color: var(--primary-color);
        background-color: white;
        display: block;
        font-size: 12px;
        height: 30px;
        margin-top: 6px;
        width: 100px;
        min-width: 100px;
        margin-bottom: auto;

        &:hover{
            background-color: var(--primary-color);
            color: white;
        }

        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
        }
    }
    .my-info-timeline-control {
        display: grid;
        grid-template-columns: 5% 95%;

        .my-info-timeline-line-control {
            display: flex;
            flex-direction: column;
            padding-top: 4px;

            .my-info-timeline-dot {
                width: 10px;
                height: 10px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 50%;
                background-color: var(--border-input);
            }

            .my-info-timeline-line-vertical {
                margin-top: 4px;
                margin-bottom: 2px;
                width: 2px;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                opacity: 0.5;
                background-color: var(--border-input);
            }
        }

        .my-info-timeline-desc-control {

            display: flex;
            flex-direction: column;
            padding-left: 16px;
            padding-right: 16px;
            margin-bottom: 16px;

            .my-info-timeline-desc-header {
                font-size: 13px;
                font-family: $fontMedium;
                color: var(--text-color);
            }

            .my-info-timeline-desc-info-control {
                margin-top: 4px;
                margin-left: 8px;
                display: flex;
                flex-direction: column;

                .my-info-timeline-desc-info-title {
                    font-size: 13px;
                    font-family: $fontRegular;
                    color: var(--text-gray-color);
                    margin-right: 4px;
                }
            }
        }
    }

    .my-info-item-no-info{
        font-size: 14px;
        font-family: $fontMedium;
        margin-left: 12px;
    }
   
    .my-info-text-name-switch-status{
        margin-left: auto;
    }

    .no-info-control{
        min-height: 300px;
        width: 100%;
        display: flex;

        .no-info-control-flex{
            margin: auto;
            display: flex;
            flex-direction: column;
        }

        .no-info-control-text{
            font-size: 16px;
            font-family: $fontRegular;
            color: var(--text-color);
            margin-left: auto;
            margin-right: auto;
        }

        .no-info-control-add-button{
            margin-top: 16px;
            width: 200px;
            height: 41px;
            color: white;
            border: none;
            background-color: var(--button-primary-color);
            font-family: $fontMedium;
            font-size: 14px;
        }
    }
    .my-info-button-view-resume{
        margin-top: 12px;
        width: 200px;
        height: 32px;
        color: white;
        font-size: 12px;
        font-family: $fontMedium;
        background-color: var(--button-primary-color);
        border-radius: 8px;
        border:none;

        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
        }
    }
    .my-info-item-control-highlight{
        background-color: var(--primary-color) !important;

        .my-info-item-highlight-header {
            font-size: 15px;
            font-family: $fontMedium;
            color: var(--text-white-color);
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .my-info-share-icon-control{
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;

        .icon{
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
        
    }
    .my-info-item-control-default{
        background-color: white;


        .my-info-item-highlight-header {
            font-size: 15px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .my-info-item-header-control{
        display: flex;

        .my-info-item-header-extra-button{
            border: 1px solid var(--primary-color);
            border-radius: 8px;
            width: auto;
            height: 36px;
            margin-left: 4px;
            color: var(--primary-color);
            font-size: 14px;
            font-family: $fontMedium;
            background-color: white;

            &:hover{
                background-color: var(--primary-color);
                color: white;
            }
        }
    }
    .my-info-item-header-button{
        font-size: 13px;
        color: var(--button-primary-color);
        font-family: $fontMedium;
        background-color: white;
        border-radius: 8px;
        border: 1px solid var(--button-primary-color);
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;

        &:hover{
            border: 1px solid var(--button-primary-color);
            background-color: var(--primary-color);
            color: white;
        }
    }
    .my-info-item-control {
        border-radius: 24px;
        background-color: white;
        padding: 16px 23px;
        width: 95%;
        overflow: auto;
        margin-bottom: 16px;
        box-shadow: var(--shadow-box);
        border:none;

        @include media-breakpoint-down(md){
            width: 100%;
        }

        .my-info-item-header {
            font-size: 15px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-top: auto;
            margin-bottom: auto;
        }

       

        .my-info-item-divider {
            width: 100%;
            height: 2px;
            margin-top: 12px;
            margin-bottom: 12px;
            opacity: 0.5;
            background-color: var(--border-input);
        }


        .my-info-item-icon {
            margin-bottom: auto;
            width: 14px;
            height: 14px;
            color: var(--text-color);
            object-fit: contain;
            margin-right: 6px;
        }

        .my-info-item-desc {
            flex-wrap: wrap;
            display: flex;
            font-size: 13px;
            margin-top: 6px;
            font-family: $fontRegular;
            color: var(--text-gray-color);
            word-break: break-word;
        }

        .my-info-item-desc-value {
            margin-left: 6px;
            display: flex;
            font-size: 13px;
            word-break: break-all;
            margin-top: auto;
            word-break: break-word;
            margin-bottom: auto;
            font-family: $fontMedium;
            color: var(--text-color);
        }
    }


    .my-info-control {
        border-radius: 24px;
        background-color: white;
        width: 95%;
        box-shadow: var(--shadow-box);
        border:none;
        min-height: 200px;
        margin-bottom: 24px;

        @include media-breakpoint-down(lg){
            width: 100% ;
        }

        .my-info-cover {
            height: 230px;
            width: 100%;
            border-radius: 8px;
            background-color: rgb(165, 165, 165);

            @include media-breakpoint-down(md){
                height: 100px;
            }

        }

        .my-info-edit-name-control{
            display: grid;
            grid-template-columns: 50% 50%;

            .my-info-edit-name-item{
                display: flex;
                flex-direction: column;

                .my-info-edit-name-title{
                    font-size: 14px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                }

                .my-info-edit-name-input{
                    width: 80%;
                    height: 41px;
                    font-size: 14px;
                    font-family: $fontMedium;
                }

                .my-info-edit-name-save-button{
                    background-color: var(--primary-color);
                    color: white;
                    font-family: $fontMedium;
                    font-size: 14px;
                    border: none;
                    border-radius: 8px;
                    width: 200px;
                    margin-top: 14px;

                    &:hover{
                        background-color: var(--primary-color);
                    }
                }
            }
        }

        .my-info-text-control {
            display: flex;
            padding: 14px;

            @include media-breakpoint-down(md){
                flex-direction: column;
            }

            .my-info-text-img-control{
                display: flex;
                flex-direction: column;

              
            }

            .my-info-text-img {
                cursor: pointer;
                width: 140px;
                height: 140px;
                border-radius: 50%;
                margin-top: auto;
                margin-bottom: auto;
                object-fit: contain;

                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .my-info-text-name-control {
                display: flex;
                flex-direction: column;
                margin-left: 24px;
                width: 100%;

                @include media-breakpoint-down(md){
                    justify-content: center;
                    margin-left: 0px;
                    margin-top: 16px;
                    padding: 0px 16px;
                }
                
            }
           
            .my-info-text-name {
                font-size: 25px;
                font-family: $fontMedium;
                color: var(--text-color);
                word-wrap: break-word;

                @include media-breakpoint-down(md){
                    text-align: center;
                    line-height: 1.2em;
                }
            }

            .my-info-text-position {
                font-size: 18px;
                font-family: $fontRegular;
                color: var(--text-color);

                @include media-breakpoint-down(md){
                    text-align: center;
                    font-size: 16px;
                    margin-top: 8px;
                }
            }

            .my-info-text-url-control {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 12px;

                
            }

            .my-info-text-url-edit-input {
                border: 1px solid var(--border-input);
                border-radius: 4px;
                margin-left: 6px;
                font-family: $fontRegular;
                font-size: 14px;
                color: var(--text-color);
                height: 31px;
                width: 100%;

               
            }

            .my-info-text-url-link-control{
                display: flex;
                @include media-breakpoint-down(md){
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
            }

            .my-info-text-url-link {
                cursor: pointer;
                font-size: 14px;
                font-family: $fontRegular;
                color: var(--link-color);
                width: auto;
                margin-top: auto;

                &:hover {
                    text-decoration: underline;
                }
            }

          
            .my-info-text-url-link-black {
                cursor: pointer;
                font-size: 14px;
                font-family: $fontRegular;
                color: var(--text-color);
                margin-top: auto;
                @include media-breakpoint-down(md){
                    text-align: center;
                    font-size: 14px;
                    margin-top: 2px;
                }
            }

            .my-info-text-url {
                font-size: 14px;
                font-family: $fontMedium;
                color: var(--text-color);
                margin-top: auto;
                @include media-breakpoint-down(md){
                    text-align: center;
                    font-size: 12px;
                    margin-top: 2px;
                }
            }


            .my-info-text-address {
                font-size: 14px;
                font-family: $fontRegular;
                color: var(--text-gray-color);

                @include media-breakpoint-down(md){
                    text-align: center;
                    font-size: 12px;
                    margin-top: 2px;
                }
            }
        }

        
    }
    .my-info-about-jobs-control{
        display: flex;
        margin-top: 4px;

        .my-info-about-jobs-title{
            font-size: 12px;
            font-family: $fontRegular;
            color: var(--text-color);
            margin-left: 3px;
        }

        .my-info-about-jobs-desc{
            font-size: 12px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-left: 3px;
        }
    }
    .my-info-about-salary-control{
        display: flex;
        margin-top: 12px;

        .my-info-about-salary-title{
            font-size: 12px;
            font-family: $fontRegular;
            color: var(--text-color);
            
            margin-left: 3px;
        }

        .my-info-about-salary-desc{
            font-size: 12px;
            font-family: $fontMedium;
            color: var(--text-color);
            width: 60%;
            margin-left: 24px;
        }
    }
    .my-info-about-lang-control{
        display: flex;
        margin-top: 12px;

        .my-info-about-lang-title{
            font-size: 12px;
            font-family: $fontRegular;
            color: var(--text-color);
            width: 40%;
            margin-left: 3px;
        }
    }
    .my-info-about-lang-grid{
        width: 100%;
        display: flex;
        text-align: left;
        margin-right: auto;

        .my-info-about-lang-text{
            font-size: 12px;
            font-family: $fontMedium;
            color: var(--text-color);
            margin-left: 3px;
        }
    }
}