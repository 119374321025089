@import "../../../App.scss";


.step-2-main{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  
    .MuiStack-root{
        padding-top: 0px !important;
        margin-bottom: 0px;
    }
    .MuiOutlinedInput-notchedOutline{
        border: none !important;
    }
        
        .step-2-main-form-control{
            background-color: white;;
            min-height: 600px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 24px;
            position: relative;
            padding: 24px 5%;
            box-shadow: 0px 0px 18px -5px var(--primary-color);

            .step-2-form-title{
                font-size: 24px;
                color: var(--primary-color);
                font-family: $fontMedium;
            }

            .step-2-form-grid{
                display: grid;
                grid-template-columns: 50% 50%;
                margin-top: 24px;
                width: 100%;

                @include media-breakpoint-down(md){
                    grid-template-columns: 100%;
                }
            }
        }

        .step-2-button-control{
            display: grid;
            grid-template-columns: 50% 50%;
            width: 100%;
            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }
        }
        
    
}