@import "../../../../App.scss";

.template-0-control {
    display: block; /* or 'display: grid;' if using grid layout */
    grid-template-columns: 100%; /* This is effective only if 'display: grid;' is used */
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    overflow: auto; /* or 'overflow: scroll;' to always show the scrollbar */


    .template-0-cms-background {

        background-color: white;
    }


    .template-0-form-control {
        width: 210mm;
        box-shadow: 0px 0px 10px var(--border-input);
        display: grid;
        min-height: 400px;
        grid-template-columns: 60mm 150mm;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md){
            grid-template-columns: 60mm 150mm;
        }

        

        .template-0-color-control {
            display: flex;
            flex-direction: column;
        }


        .template-0-left-control {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            padding-bottom: 34px;
            padding-left: 0px;
            padding-right: 0px;



            .template-0-profile-control {
                display: table;
                margin-left: auto;
                margin-right: auto;
                margin-top: 36px;
                margin-bottom: 68px;

                .template-0-profile {
                    width: 200px;
                    height: 200px;
                    margin: auto;
                    object-fit: cover;
                    border-radius: 50%;

                    @include media-breakpoint-down(xl){
                        width: 150px;
                        height: 150px;
                    }
                    @include media-breakpoint-down(lg){
                        width: 120px;
                        height: 120px;
                    }

                    @include media-breakpoint-down(md){
                        width: 200px;
                        height: 200px;
                    }
                }

                .template-0-profile-button {
                    width: 150px;
                    height: 46px;
                    color: white;
                    background-color: var(--primary-color);
                }
            }

            .template-0-item-control {
                padding-left: 16px;
                margin-top: 16px;
            }

            .template-0-title-left {
                font-size: 20px;
                color: white;
                font-family: $fontMedium;
                word-wrap: break-word;
            }

            .template-0-divider {
                width: 100%;
                height: 3px;
                background-color: white;
            }



        }

        .template-0-content-control {
            display: flex;
            margin-top: 12px;
        }

        .template-0-content-black {
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            font-size: 13px;
            margin-bottom: 4px;
            margin-left: 12px;
            margin-right: 36px;
            color: var(--text-color);
            word-wrap: break-word;
        }

        .template-0-content {
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            font-size: 11px;
            margin-bottom: 4px;
            margin-left: 6px;
            margin-right: 6px;
            word-wrap: break-word;
            word-break: break-word;
            color: white !important;
        }

        .template-0-content > span{
            word-break: break-word;
        }

        .template-0-right-control {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            padding-top: 16px;
            padding-bottom: 34px;
            padding-left: 24px;
            padding-right: 0px;

            .template-0-exp-control {
                margin-top: 48px;
                display: flex;
                flex-direction: column;

                .template-0-exp-title {
                    font-size: 26px;
                    color: var(--text-color);
                    word-wrap: break-word;
                    font-family: $fontMedium;
                }

                .template-0-exp-divider {
                    width: 100%;
                    height: 3px;
                    background-color: var(--border-input);
                }
            }


            .template-0-name-control {

                .template-0-name {
                    font-size: 36px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                    word-wrap: break-word;
                }

                .template-0-position {
                    font-size: 22px;
                    font-family: $fontRegular;
                    color: var(--text-color);
                    margin-right: 36px;
                    word-wrap: break-word;
                }

                .template-0-cover-letter {
                    font-size: 13px;
                    font-family: $fontRegular;
                    color: var(--text-color);
                    margin-right: 36px;
                    word-wrap: break-word;
                }
            }

        }
    }
}

@media print {}