@import "../../../../App.scss";



.talent-sauce-drop-resume-main {

    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 2;
    background: linear-gradient(180deg, #F26622 -3.97%, #2C3694 67.06%);


    @include media-breakpoint-down(md) {
        height: auto;
    }

    .MuiOutlinedInput-root{
        border-radius: 8px;
        border:none;

        &:focus{
            border-radius: 8px;
            border:none !important;
        }
    }
    .MuiInputBase-input{
        color: var(--text-white-color);
        border-radius: 8px;
        border:none;

        &:focus{
            border-radius: 8px;
            border:none !important;
        }

        &::placeholder{
            font-family: $fontRegular !important;
            color: #fff !important;
        }
    }
    .talent-sauce-drop-resume-overlay-black {
        background-color: black;
        opacity: 0.6;
        width: 100%;
        min-height: 100vh;
        height: max-content;
        position: fixed;
        z-index: 2;

        @include media-breakpoint-down(md) {
            opacity: 0.8;
        }
    }

    .talent-sauce-drop-resume-loading-control{
        min-height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 9;
    
        .talent-sauce-drop-resume-loading-item{
            margin: auto;
            display: flex;
            flex-direction: column;

            .talent-sauce-drop-resume-loading-progress{
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .talent-sauce-drop-resume-loading-text{
                margin-top: 24px;
                font-size: 18px;
                color: var(--text-white-color);
                font-family: $fontMedium;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .talent-sauce-drop-resume-cover-header-control {

        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(lg) {
            min-height: 800px;

        }

        @include media-breakpoint-down(md) {
            min-height: 700px;
            height: auto;

        }

        .talent-sauce-drop-resume-cover-img {
            position: absolute;
            background-image: url(../../../../assets/images/talent-sauce/cover-drop-resume.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            z-index: 0;
            mix-blend-mode: color-burn;
            opacity: 0.5;
        }
    }

    .talent-sauce-drop-resume-control {
        margin-top: 36px;
        border-radius: 34px;
        border: 1px solid rgba(238, 238, 238, 0.20);
        background: #11013680;
        box-shadow: 0px 5.1px 10.2px -5.1px rgba(24, 39, 75, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(10.199999809265137px);
        padding: 36px;
        z-index: 3;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;

        @include media-breakpoint-down(md){
            width: 95%;
        }
    }

    .talent-sauce-drop-resume-title-control{
        display: flex;
        font-size: 32px;
        font-family: $fontMedium;

        .talent-sauce-drop-resume-title-white{
            color: var(--text-white-color);
        }
        .talent-sauce-drop-resume-title-orange{
            color: #FF9663;
        }
    }

    .talent-sauce-drop-resume-desc-text{
        margin-top: 4px;
        font-size: 16px;
        font-family: $fontRegular;
        color: var(--text-white-color);
    }

    .talent-sauce-drop-resume-form-control {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }
    }

    .talent-sauce-drop-resume-form-control-full {
        grid-template-columns: 100% !important;
    }

    .talent-sauce-drop-resume-field-item-33 {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .talent-sauce-drop-resume-field-item-50 {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .talent-sauce-drop-resume-upload-file {
        text-align: left;
        border-radius: 8px;
        background-color: #110136;
        border: 1px solid white;
        padding-left: 16px;
        height: 41px;
        display: flex;
        width: 90%;
        &:hover {
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .talent-sauce-drop-resume-upload-file-text{
        margin-left: 16px;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
        font-family: $fontRegular;
        color: var(--text-white-color);
    }
    .talent-sauce-drop-resume-upload-file-button {
        background-color: #F26622;
        border-radius: 20px;
        border: none;
        font-size: 13px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 16px;
        padding-right: 16px;
        height: 35px;
        width: auto;
        &:hover {
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }

        &:active {
            background-color: var(--primary-color) !important;
        }
    }

    .talent-sauce-drop-resume-wave{
        bottom: 0;
        margin-top: auto;
        width: 100%;
        z-index: 3;
        height: 150px;
        background: url(../../../../assets/images/talent-sauce/wave.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: sticky;

        @include media-breakpoint-down(md){
            display: none;
        }
    }

    .talent-sauce-drop-resume-t{
        width: auto;
        height: 40%;
        object-fit: contain;
        bottom: 0;
        margin-right: 25%;
        z-index: 2;
        right: 0;
        margin-top: auto;
        position: absolute;

        @include media-breakpoint-down(md){
            height: 20%;
            margin-right: 25%;
        }
    }

    .talent-sauce-drop-resume-s{
        width: auto;
        height: 40%;
        object-fit: contain;
        bottom: 0;
        margin-right: 5%;
        z-index: 2;
        right: 0;
        margin-top: auto;
        position: absolute;

        @include media-breakpoint-down(md){
            height: 20%;
            margin-right: 2%;
        }
    }

    .talent-sauce-drop-resume-submit-button {
        background-color: #F26622;
        border-radius: 20px;
        border: none;
        font-size: 16px;
        color: var(--text-white-color);
        font-family: $fontMedium;
        margin-top: 48px;
        padding-left: 36px;
        padding-right: 36px;
        height: 51px;
        width: 300px;
        &:hover {
            background-color: var(--primary-color);
            color: var(--text-white-color);
        }

        &:active {
            background-color: var(--primary-color) !important;
        }

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }
    .talent-sauce-drop-resume-title {
        font-size: 16px;
        font-family: $fontRegular;
        color: var(--text-white-color);
        margin-bottom: 4px;
    }

    .talent-sauce-drop-resume-error {
        font-size: 12px;
        font-family: $fontRegular;
        color: var(--red-color);
    }

    .talent-sauce-drop-resume-input {
        width: 90%;
        border-radius: 8px;
        background-color: #110136;
        border: 1px solid white;
        font-size: 14px;
        color: var(--text-white-color);
        font-family: $fontRegular;

        &::placeholder{
            font-family: $fontRegular !important;
        }

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }

    .talent-sauce-drop-resume-text-area {
        padding: 6px 16px;
        width: 95%;
        border-radius: 8px;
        background-color: #110136;
        border: 1px solid white;
        font-size: 14px;
        color: var(--text-white-color);
        font-family: $fontRegular;

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }

    .talent-sauce-drop-resume-button-submit {
        border-radius: 8px;
        background-color: var(--primary-color);
        color: var(--text-white-color);
        border: 1px solid var(--primary-color);
        font-size: 14px;
        font-family: $fontMedium;
        margin-top: 16px;
        width: 95%;
        height: 51px;

        &:hover {
            background-color: var(--primary-color);
            box-shadow: var(--shadow-box);
        }

        &:active {
            background-color: var(--primary-color) !important;
        }
    }
}