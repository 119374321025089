@import "../../App.scss";


.interview-result-modal-main{
   

    .modal-dialog {
        max-width: 50% !important;
        width: 50% !important;
        min-height: 90vh !important;
        margin-bottom: 5% !important;
       
        @include media-breakpoint-down(md){
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        height: 90vh !important;
        width: 100%;
        overflow-y: scroll;
    }

    .interview-result-main-control{
        display: flex;
        padding: 16px;
        flex-direction: column;
        height: 100%;
      

        .interview-result-divider{
            margin-top: 12px;
            margin-bottom: 12px;
            border-color: var(--border-input);
            font-size: 15px;
            font-family: $fontMedium;
            color: var(--text-color);
        }
        .interview-result-header-control{
            display: flex;

            .interview-result-title{
                font-size: 16px;
                font-family: $fontMedium;
                color: var(--text-color);
                margin-top: auto;
                margin-bottom: auto;
            }

            .interview-result-close{
                cursor: pointer;
                margin-left: auto;
                font-size: 24px;
                color: var(--text-color);
                margin-top: auto;
                margin-bottom: auto;
            }

        }
       

        .interview-result-flex-control{
            width: 100%;
            display: flex;
            flex-direction: column;

            .interview-result-flex-item{
                margin-top: 12px;
                display: flex;
                flex-direction: column;
            }

            .interview-result-flex-question{
                font-size: 14px;
                font-family: $fontMedium;
                color: var(--text-color);
            }
            .interview-result-flex-answer{
                font-size: 14px;
                font-family: $fontRegular;
                color: var(--text-color);
            }
        }
    }
}