@import "../../App.scss";


.loading-modal-control{
    display: flex;
    flex-direction: column;
    padding: 24px 16px;


    .loading-modal-progress{
        width: 30px;
        height: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .loading-modal-text{
        font-family: $fontMedium;
        color: var(--text-color);
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 48px;
    }
}