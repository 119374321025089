@import "../../../App.scss";

.step-3-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .step-3-loading-control{
        display: flex;
        margin: auto;
        .step-3-loading-main{
            margin: auto;
            display: flex;
            flex-direction: column;
        }

        .step-3-loading-progress{
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .step-3-loading-text{
            font-size: 14px;
            color: var(--text-color);
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
        }
    }
    .step-3-main-form-control{
        background-color: white;;
        min-height: 250px;
        width: 80%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        align-items: center;
        border-radius: 24px;
        padding: 24px 5%;
        position: relative;
        box-shadow: 0px 0px 18px -5px var(--primary-color);
        @include media-breakpoint-down(xl){
            width: 100%;
            padding: 24px 24px;
        }
        @include media-breakpoint-down(md){
            width: 100%;
            padding: 24px 24px;
        }

        .step-3-form-title{
           
            font-size: 24px;
            color: var(--primary-color);
            font-family: $fontMedium;
            margin-bottom: 24px;
        }
        .step-3-form-input-control{
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .step-3-form-margin{
            margin-top: auto;
            width: 100% !important;
        }

        .step-3-url-control{
            margin-top: 8px;
            display: flex;
            flex-direction: row;

            @include media-breakpoint-down(md){
                flex-direction: column;
            }
        }

      

        .step-3-total-url{
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;
            width: fit-content;
            font-size: 16px;
            color: var(--text-color);
            font-family: $fontRegular;
        }
        .step-3-total-url-text{
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;
            width: fit-content;
            font-size: 14px;
            color: var(--text-color);
            font-family: $fontRegular;
        }
    }

    .step-3-button-control{
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        margin-top: 36px;

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }
    }

}