@import "../../../../App.scss";

.about-lang-control{
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    
    
    .about-lang-title{
        font-size: 13px;
        font-family: $fontMedium;
        color: var(--text-color);
    }

    .form-select{
        color: var(--text-color);
        font-size: 14px;
    }

    .about-lang-check-grid{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        @include media-breakpoint-down(md){
            grid-template-columns: 50% 50%;
        }
        .MuiFormControlLabel-label{
            font-size: 13px;
            font-family: $fontRegular !important;
            color: var(--text-color);
        }
        .about-lang-check-item{
            font-size: 13px;
            font-family: $fontRegular;
            color: var(--text-color);
        }
    }

    .about-lang-check-grid-3{
        display: grid;
        grid-template-columns: 33% 33% 33%;

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }

        .MuiFormControlLabel-label{
            font-size: 13px;
            font-family: $fontRegular !important;
            color: var(--text-color);
        }

        .about-lang-check-item{
            font-size: 13px;
            font-family: $fontRegular;
            color: var(--text-color);
        }
    }
}