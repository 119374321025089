@import "../../App.scss";


.menu-main {
    width: 100px;
    height: auto;
    min-height: 100vh;
    display: flex;
    background: var(--bg-menu-gradient);
    flex-direction: column;
    background-position: bottom center;
    padding: 12px;
    animation: drawerWidthBack 0.5s forwards;
    overflow-y: auto;
    @include media-breakpoint-down(md) {
        width: 100%;
        min-height: auto;
        animation: drawerWidthBack100 0.5s forwards;
    }
}

.menu-main-no-animation{
    width: 100px;
    height: auto;
    min-height: 100vh;
    display: flex;
    background: var(--bg-menu-gradient);
    background-position: bottom center;
    flex-direction: column;
    padding: 12px;
    overflow-y: auto;
    @include media-breakpoint-down(md) {
        width: 100%;
        min-height: auto;
    }
}

.menu-main-active {
    width: 20%;
    height: auto;
    min-height: 100vh;
    display: flex;
    background: var(--bg-menu-gradient);
    flex-direction: column;
    background-position: bottom center;
    padding: 12px;
    animation: drawerWidth 0.5s forwards;
    overflow-y: auto;
    z-index: 1;
    @include media-breakpoint-down(lg) {
        width: 40%;
        height: auto;
        animation: drawerWidth100 0.5s forwards;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: auto;
        animation: drawerWidth100 0.5s forwards;
    }

    .MuiListItemIcon-root{
        min-width: 24px !important;
    }
}

.menu-icon-control {
    display: flex;
    max-width: 100%;

    .menu-icon-size {
        cursor: pointer;
        color: white;
        margin-top: 16px;
        font-size: 25px;
        margin-left: 24px;
        height: 30px;
    }

    .menu-icon-logo {
        display: none;
        width: auto;
        height: 30px;
        margin-top: 16px;
        margin-right: 8px;
        margin-left: auto;
        object-fit: contain;
        @include media-breakpoint-down(md){
            display: block;
        }
    }

    .menu-icon-logo-active {
        display: block;
        width: 60%;
        height: auto;
        margin-top: 16px;
        margin-left: auto;
        margin-right: 8px;
        object-fit: contain;

        @include media-breakpoint-down(md){
            display: block;
        }
    }
}

.menu-list-item-control {
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    @include media-breakpoint-down(md){
        margin-top: 24px;
    }

    .list-group-item {
        background-color: transparent !important;
        border: none;
        color: white;
        font-family: $fontMedium !important;
        width: 100%;
    }

    .MuiTypography {
        font-family: $fontMedium !important;
    }

    .menu-list-group-control {
        width: 100%;
        display: flex;
        background-color: transparent;

    }

    .menu-list-item-text-control {
        font-family: $fontRegular;
    }

    .menu-list-group-item-icon {
        color: white;
    }

    .menu-list-group-item-icon-active {
        color: white;
    }
    .menu-list-group-item-icon-chevron-noanimation {
        margin-left: auto;
        color: white;
    }

    .menu-list-group-item-icon-chevron {
        margin-left: auto;
        color: white;
        animation: rotateChevron 0.5s forwards;
    }

    .menu-list-group-item-icon-chevron-active {
        margin-left: auto;
        animation: rotateChevronActive 0.5s forwards;
        color: white;
    }

    .menu-list-item-child-control {
        margin-left: 16px;
        font-family: $fontRegular;
    }

}

.menu-item-group-mobile-control {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(md){
        display: none;
    }

    .menu-item-group-mobile {
        display: flex;
        flex-direction: column;
        margin-bottom: 36px;
        word-break: break-word;
        cursor: pointer;

        .menu-item-group-mobile-text {
            font-size: 11px;
            font-family: $fontMedium;
            color: white;
            margin-top: 12px;
            text-align: center;
        }

        .menu-item-group-mobile-icon {
            font-size: 24px;
            color: white;
        }
    }
}

@keyframes drawerWidth {
    0% {
        width: 100px;
    }

    100% {
        width: 20%;

    }
}
@keyframes drawerWidth100 {
    0% {
        width: 100%;
        height: 200px;
    }

    100% {
        width: 100%;
        height: 500px;

    }

  
}

@include media-breakpoint-down(lg){
           
    @keyframes drawerWidth100 {
        0% {
            width: 100%;
            height: 200px;
        }
    
        100% {
            width: 60%;
            height: 500px;
    
        }
    
      
    }
}
@include media-breakpoint-down(md){
           
    @keyframes drawerWidth100 {
        0% {
            width: 100%;
            height: 200px;
        }
    
        100% {
            width: 100%;
            height: 500px;
    
        }
    
      
    }
}
@keyframes drawerWidthBack100 {
    0% {
        width: 100%;
        height: 500px;
    }

    100% {
        width: 100%;
        height: 100px;
    }
}
@keyframes drawerWidthBack {
    0% {
        width: 20%;
       
    }
    100% {
        width: 100px;
    }
}

@keyframes rotateChevron {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotateChevronActive {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}