@import "../../../App.scss";

.import-resume-main {
    width: 100%;
    display: flex;
    flex-direction: column;

    .modal-dialog {
        max-width: 80% !important;
        width: 80% !important;
        height: auto;
        min-height: 90vh !important;
        margin-bottom: 5% !important;

        @include media-breakpoint-down(md) {
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content {
        min-height: 90vh !important;
    }

    .import-resume-parsing-control {
        display: flex;
        height: 300px;
        width: 100%;

        .import-resume-parsing-main {
            margin: auto;
            display: flex;
            flex-direction: column;

            .import-resume-parsing-key-text {
                font-size: 14px;
                font-family: $fontMedium;
                text-align: center;
                color: var(--text-color);

            }

            .import-resume-parsing-loading {
                width: 30px;
                height: 30px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
                margin-bottom: 16px;
            }

            .import-resume-parsing-loading-text {
                font-size: 14px;
                margin-top: 24px;
                font-family: $fontRegular;
            }
        }
    }
}